<div class="wrap">
  <div style="display: flex">
    <span class="title first"> <img class="tboxfitImg" src="../../../../assets/images/tboxfit_logo.png" />을 시청할 </span>
    <span class="title">프로필을 선택하세요.</span>
  </div>
  <div class="profiles">
    <div *ngFor="let profile of profiles">
      <div class="wrap-existing" (click)="selectedProfile(profile)">
        <div *ngIf="editMode" class="blur">
          <img src="../../../assets/images/edit_pen.png" />
        </div>
        <img src="{{ profile.photo }}" alt="ProfilePhoto" class="Profile_Photo" />
      </div>
      <!-- <img class="none-icon" src="../../../assets/images/defpf.svg" /> -->
      <span>{{ profile.name }}</span>
    </div>
    <div *ngIf="profiles?.length < maxProfile && editMode === true" class="profiles_add">
      <img class="add-profile" (click)="changeState(2)" src="../../../assets/images/addProfile.png" alt="" />
      <span>프로필 추가</span>
      <!-- todo 추가 확인하는 장치 -->
    </div>
  </div>
  <button *ngIf="editMode === false" (click)="changeEditModeHandler(true)">프로필 관리</button>
  <button *ngIf="editMode === true" (click)="complete()">완료</button>
</div>
<div class="body_bg"></div>
