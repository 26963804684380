<div class="wrap">
  <mat-tab-group mat-align-tabs="center" dynamicHeight animationDuration="0ms" (selectedTabChange)="checkTabChange($event)" [(selectedIndex)]="mySelTabIndex">
    <mat-tab label="이용권" style="box-shadow: 0 0 5px #000000"><app-subscription></app-subscription></mat-tab>
    <!--- 디자인 , 기획 확정나면 주석해제
    <mat-tab label="이벤트" style="box-shadow: 0 0 5px #000000"><app-event></app-event></mat-tab>
    <mat-tab label="T-BOX" style="box-shadow: 0 0 5px #000000"><app-tbox></app-tbox></mat-tab>
    -->
    <ng-container *ngFor="let c of cates">
      <mat-tab label="{{ c.cate }}">
        <div class="tab_tit">
          <h3>STORE</h3>
          <i class="material-icons">&#xe5cc;</i>
          <h3>{{ c.cate }}</h3>
        </div>

        <!-- 상품정렬 -->
        <div style="display: flex; justify-content: flex-end; align-items: center">
          <h4 style="margin: 0 10px">정렬</h4>
          <mat-select [(ngModel)]="selectedSort" name="s" (selectionChange)="setSort($event.value)" class="sort_box">
            <mat-option *ngFor="let s of sorts" [value]="s.value">
              {{ s.viewValue }}
            </mat-option>
          </mat-select>
        </div>
        <br />

        <div class="container-fluid" style="padding: 0">
          <div *ngFor="let item of itemsSorted | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize">
            <div class="col-xs-6 col-sm-6 col-md-3" style="overflow: hidden">
              <p><!-- For when categorry is added--></p>
              <img src="{{ item.main_img }}" alt="" class="item_img" />
              <div class="" style="padding-top: 10px">
                <p>[T-BOX MEDIA]</p>
                <p style="font-size: 15px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.name }}</p>
                <p *ngIf="item.onsale === false">
                  <strong>{{ item.price | currency : "KRW" : "symbol" }}</strong
                  ><!--정상가격-->
                </p>
                <p *ngIf="item.onsale === true">
                  <strong>{{ item.sale_price | currency : "KRW" : "symbol" }}</strong
                  >&nbsp;<!--할인가격-->
                  <strong style="text-decoration: line-through; color: #999999; font-weight: 400; font-size: 13px">{{ item.price | currency : "KRW" : "symbol" }}원</strong>
                </p>
                <!-- TODO remove when api is done -->
                <!-- {{ item.price }} -->
              </div>

              <div style="display: flex">
                <div *ngIf="item.naver_store !== 'soldout'">
                  <a [href]="item.naver_store" target="_blank">
                    <!-- {{ 'Naver Store' | translate }} -->
                    <button (click)="naverStoreClicked(item)" mat-stroked-button class="btn_linked"><span class="material-icons" style="font-size: 18px; margin-right: 5px">local_offer</span>구매하기</button>
                  </a>
                </div>
                &nbsp;
                <div *ngIf="item.interpark_store !== ''">
                  <a [href]="item.interpark_store" target="_blank">
                    <!-- {{ 'Interpark Store' | translate }} -->
                    <button (clicK)="interparkStoreClicked(item)" mat-stroked-button>구매하기</button>
                  </a>
                </div>

                <div *ngIf="item.naver_store == 'soldout'">
                  <button mat-stroked-button disabled class="btn_disabled"><span class="btn_disabled_margin">상품준비중</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ngb-pagination
          [collectionSize]="itemsSorted.length"
          [(page)]="page"
          [pageSize]="pageSize"
          [maxSize]="5"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
          class="d-flex justify-content-center"
          style="font-size: 15px"
        >
        </ngb-pagination>
      </mat-tab>
    </ng-container>
    <!-- <mat-tab label="Spin Bike">
      <div class="tab_tit">
        <h3>STORE</h3> <i class="material-icons">&#xe5cc;</i><h3>link label </h3>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</div>
