import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { textData } from "src/app/shared";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../user/profile/profile.component";

import { BoardService } from "src/app/shared/services/board.service";
import { VodService } from "src/app/shared/services/vod.service";

interface BannerData {
  id: number;
  class: string;
  sajin: string;
  caption_main: string;
  caption_sub: string;
}

@Component({
  selector: "app-body",
  templateUrl: "./body.component.html",
  styleUrls: ["./body.component.css"]
})
export class BodyComponent implements OnInit {
  // swiperconfig: SwiperConfigInterface = {
  //   observer: true,
  //   direction: "horizontal",
  //   threshold: 10,
  //   spaceBetween: 10,
  //   slidesPerView: 1,
  //   centeredSlides: true,
  //   navigation: true,
  //   pagination: true,
  //   loop: true,
  //   // effect: 'coverflow',
  //   autoplay: {
  //     delay: 4000,
  //     disableOnInteraction: true
  //   },
  //   breakpoints: {
  //     900: {
  //       slidesPerView: 1,
  //       spaceBetween: 0
  //     }
  //   }
  // };

  // pagination: SwiperPaginationInterface = {
  //   el: ".swiper-pagination",
  //   clickable: true
  // };

  // swiperconfigText: SwiperConfigInterface = {
  //   observer: true,
  //   direction: "horizontal",
  //   threshold: 10,
  //   spaceBetween: 10,
  //   slidesPerView: 1,
  //   centeredSlides: true,
  //   navigation: true,
  //   pagination: true,
  //   loop: true,
  //   // effect: 'coverflow',
  //   autoplay: {
  //     delay: 4000,
  //     disableOnInteraction: true
  //   },
  //   breakpoints: {
  //     900: {
  //       slidesPerView: 1,
  //       spaceBetween: 0
  //     }
  //   }
  // };

  // paginationText: SwiperPaginationInterface = {
  //   el: ".swiper-pagination",
  //   clickable: true
  // };

  imgSet = ["../../../../assets/images/Live/AI.png", "../../../../assets/images/Live/IOT.png", "../../../../assets/images/Live/DATA.png"];
  textSet = textData;

  banners = [];

  vodLinks = [];

  loading = true;
  bannersLoaded = false;

  @ViewChild("carousel", { static: true }) carousel: NgbCarousel;
  constructor(private boardService: BoardService, private vodService: VodService, public router: Router, public dialog: MatDialog) {
    this.boardService.getMainBanner().subscribe({
      next: value => {
        value.forEach(element => {
          this.banners.push({
            id: element.id,
            img: element.img,
            title: element.title,
            content: element.content
          });
        });
      },
      complete: () => {
        this.bannersLoaded = true;
      }
    });
    this.vodService.getVodCategory().subscribe({
      next: value => {
        value.forEach(element => {
          this.vodLinks.push({
            id: element.id,
            name: element.name,
            vod_type: element.vod_type,
            vod_id: element.vod_id
          });
        });
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
  ngOnInit() {}

  goToVod(vod) {
    this.router.navigate(["kr/vod", vod]);
  }

  goToTboxVod(vod) {
    this.router.navigate(["kr/tbox_vod", vod]);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogVod, {
      width: "1000px",
      height: "auto"
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}

@Component({
  selector: "app-dialogVod",
  templateUrl: "./dialogVod.component.html",
  styleUrls: ["./body.component.css"]
})
export class DialogVod {
  constructor(public dialogRef: MatDialogRef<DialogVod>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  onNoClick() {
    this.dialogRef.close();
  }
}
