import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Vod, VodListCategory, VodCategory } from "../models/vod";

@Injectable({
  providedIn: "root"
})
export class VodService {
  apiVodUrl = environment.apiUrl + "/vod";

  constructor(private http: HttpClient) {}
  returnUrl() {
    return this.apiVodUrl;
  }

  getVodList(vod_type, profile_id): Observable<any> {
    if (localStorage.getItem("token")) {
      const header = {
        headers: new HttpHeaders({
          Authorization: JSON.parse(localStorage.getItem("token"))?.access,
          "Content-Type": "application/json"
        })
      };
      const body = {
        vod_type: vod_type,
        profile_id: profile_id
      };
      return this.http.post(this.apiVodUrl + "/", body, header);
    } else {
      const header = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      const body = {
        vod_type: vod_type
      };
      return this.http.post(this.apiVodUrl + "/", body, header);
    }
  }

  getVodCategory(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = {};
    return this.http.post(this.apiVodUrl + "/category/vod", body, header);
  }

  addVodView(profile_id, vod_id, save_time): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile: profile_id,
      vod: vod_id,
      save_time: save_time
    };
    return this.http.post(this.apiVodUrl + "/view/add", body, header);
  }

  getVodView(profile_id, vod_id): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile_id: profile_id,
      vod_id: vod_id
    };
    return this.http.post(this.apiVodUrl + "/view", body, header);
  }

  addVodViewLog(profile_id, vod_id, time): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile: profile_id,
      vod: vod_id,
      time: time
    };
    return this.http.post(this.apiVodUrl + "/view/log/add", body, header);
  }

  getVodViewLog(profile_id): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile_id: profile_id
    };
    return this.http.post(this.apiVodUrl + "/view/log", body, header);
  }

  addVodFavorite(profile_id, vod_id): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile_id: profile_id,
      vod_id: vod_id
    };
    return this.http.post(this.apiVodUrl + "/favorites/add", body, header);
  }

  removeVodFavorite(profile_id, vod_id): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile_id: profile_id,
      vod_id: vod_id
    };
    return this.http.post(this.apiVodUrl + "/favorites/remove", body, header);
  }

  getVodFavorite(profile_id): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile_id: profile_id
    };
    return this.http.post(this.apiVodUrl + "/favorites", body, header);
  }

  getVodFavoriteWithVod(profile_id): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      profile_id: profile_id
    };
    return this.http.post(this.apiVodUrl + "/favorites/vod", body, header);
  }

  getVodCategoryList(vod_type: number): Observable<VodCategory[]> {
    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = {
      vod_type: vod_type
    };
    return this.http.post<VodCategory[]>(this.apiVodUrl + "/category/list", body, header);
  }

  getVodListCategory(categoryId: number): Observable<VodListCategory> {
    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = {
      category_id: categoryId
    };
    return this.http.post<VodListCategory>(this.apiVodUrl + "/list", body, header);
  }

  getVodData(vodId: number, profileId?: number): Observable<Vod> {
    if (profileId == undefined) {
      const header = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      const body = {
        vod_id: vodId
      };
      return this.http.post<Vod>(this.apiVodUrl + "/data", body, header);
    }

    const header = {
      headers: new HttpHeaders({
        Authorization: JSON.parse(localStorage.getItem("token"))?.access,
        "Content-Type": "application/json"
      })
    };
    const body = {
      vod_id: vodId,
      profile_id: profileId
    };
    return this.http.post<Vod>(this.apiVodUrl + "/data", body, header);
  }
}
