<div *ngIf="userSubscription" class="col-md-6" style="padding: 0px 10px 0px 0px">
  <div class="card_box info_card" style="height: 330px">
    <div class="card_tit"><h3 style="margin: 0">정기 이용권</h3></div>
    <form>
      <table>
        <tr>
          <th>
            <p>종류</p>
          </th>
          <td>{{ userSubscription[0]?.subscription.name }}</td>
        </tr>
        <tr>
          <th>
            <p>금액</p>
          </th>
          <td>{{ userSubscription[0]?.subscription.price }}</td>
        </tr>
        <tr>
          <th>
            <p>상태</p>
          </th>
          <td>{{ userSubscription[0]?.status }}</td>
        </tr>
        <tr>
          <th>
            <p>시작일</p>
          </th>
          <td>{{ userSubscription[0]?.startDate }}</td>
        </tr>
        <tr>
          <th>
            <p>종료일</p>
          </th>
          <td>{{ userSubscription[0]?.endDate }}</td>
        </tr>
        <tr>
          <th>
            <p>다음 결제일</p>
          </th>
          <td>{{ userSubscription[0]?.reserveExecuteAt }}</td>
        </tr>
      </table>
    </form>
  </div>
</div>

<div *ngIf="userSubscription" class="col-md-6" style="padding: 0px 0px 0px 10px">
  <div class="card_box info_card" style="height: 330px">
    <div class="card_tit"><h3 style="margin: 0">결제 수단</h3></div>
    <div class="Payment_Wrap">
      <div class="Payment_Card">
        <p class="Card_name">{{ userSubscriptionBillingKey[0]?.cardCompany }}</p>
        <div class="Bottom_info">
          <p class="Card_Day">{{ userSubscriptionBillingKey[0]?.billingExpireAt }}</p>
          <p class="Card_Num">{{ userSubscriptionBillingKey[0]?.cardNo }}</p>
        </div>
      </div>
    </div>
    <div>
      <button *ngIf="userSubscription[0]?.status === '정상'" mat-raised-button class="profile_button out_membership" (click)="openDialog()">해지신청</button>
      <button
        *ngIf="userSubscription[0]?.status === '정상' || userSubscription[0]?.status === '취소'"
        mat-raised-button
        class="profile_button change_card"
        (click)="reRequestBillingKey(userSubscription[0]?.subscription)"
      >
        결제 카드 변경
      </button>
    </div>
  </div>
</div>
