import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUserUrl = environment.apiUrl + '/user';

  constructor(
    private http: HttpClient,
  ) { }
  returnUrl() {
    return this.apiUserUrl;
  }
  
  signup(data): Observable<any> {
    const body = {
      username: data.value.username,
      email: data.value.email,
      fullname: data.value.fullname,
      password: data.value.password
    }
    return this.http.post(this.apiUserUrl + '/signup', body, httpOptionsNoToken);
  }

  signin(data): Observable<any> {
    const body = {
      user: data.user,
      password: data.password
    }
    return this.http.post(this.apiUserUrl + '/signin', body, httpOptionsNoToken);
  }

  signout(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = { }
    return this.http.post(this.apiUserUrl + '/signout', body, header);
  }

  refreshToken(refresh): Observable<any> {
    const body = {
      refresh: refresh
    }
    return this.http.post(this.apiUserUrl + '/token/refresh', body, httpOptionsNoToken);
  }

  vaildUsername(username): Observable<any> {
    const body = { }
    return this.http.post(this.apiUserUrl + '/username/vaild/' + username, body, httpOptionsNoToken);
  }

  vaildEmail(email): Observable<any> {
    const body = { }
    return this.http.post(this.apiUserUrl + '/email/vaild/' + email, body, httpOptionsNoToken);
  }

  findUsername(data): Observable<any> {
    const body = {
      fullname: data.fullname,
      email: data.email
    }
    return this.http.post(this.apiUserUrl + '/username/find', body, httpOptionsNoToken );
  }

  findPassword(data): Observable<any> {
    const body = {
      username: data.username,
      fullname: data.fullname,
      email: data.email
    }
    return this.http.post(this.apiUserUrl + '/password/find', body, httpOptionsNoToken);
  }

  updatePassword(data): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = {
      current_password: data.currentPassword,
      new_password: data.newPassword
    }
    return this.http.post(this.apiUserUrl + '/password/update', body, header)
  }

  updateAddress(data): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = {
      roadAddr: data.roadAddr,
      jibunAddr: data.jibunAddr,
      zipNo: data.zipNo,
      detail_juso: data.detail_juso
    }
    return this.http.post(this.apiUserUrl + '/address/update', body, header);
  }

  updateCellphone(cellphone): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = {
      cellphone: cellphone,
    }
    return this.http.post(this.apiUserUrl + '/cellphone/update', body, header);
  }

  inactivateUser(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = { }
    return this.http.post(this.apiUserUrl + '/inactivate', body, header);
  }

  activateUser(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = { }
    return this.http.post(this.apiUserUrl + '/activate', body, header);
  }

  createProfile(name, photo) : Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
      })
    }
    const body = new FormData();
    body.append('name', name);
    body.append('photo', photo);
    return this.http.post(this.apiUserUrl + '/profile/create', body, header);
  }

  updateProfile(id, name, photo) :Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
      })
    }
    const body = new FormData();
    body.append('id', id);
    body.append('name', name);
    body.append('photo', photo);
    return this.http.post(this.apiUserUrl + '/profile/update', body, header);
  }

  deleteProfile (id) : Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = {
      id: id
    }
    return this.http.post(this.apiUserUrl + '/profile/delete', body, header);
  }

  getProfile() : Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    return this.http.get(this.apiUserUrl + '/profile', header)
  }

}