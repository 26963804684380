<div class="wrap">
  <div class="container">
    <div class="row">
      <!--vod view-->
      <ng-template [ngIf]="open === true">
        <div class="col-lg-8" style="padding: 0">
          <div class="view view_vod">
            <div class="view_box">
              <!--loading spinner-->
              <div *ngIf="loading === true" class="wrap_spin">
                <mat-spinner diameter="50" class="custom-spinner" style="margin: 0 auto; position: absolute; bottom: 10%; left: calc(50% - 25px)"></mat-spinner>
                <p style="color: #fec40d; text-align: center; padding-top: 20px">잠시만 기다려주세요 데이터를 로딩중입니다</p>
              </div>

              <div *ngIf="loading === false">
                <div [hidden]="!isString">
                  <video
                    onloadeddata="{{ playVod() }}"
                    id="myVideo"
                    #myVod
                    src="{{ playSource }}"
                    oncanplay="{{ setCurrentTime() }}"
                    width="100%"
                    disablePictureInPicture
                    controls
                    controlsList="nodownload"
                    oncontextmenu="return false;"
                    (ended)="ended()"
                    readyState
                    ended
                    (pause)="pauseEvent()"
                    pause
                    (play)="playEvent()"
                  ></video>
                </div>
              </div>
            </div>
            <!-- <div class="btn_box">
                <button *ngIf="continue==false" mat-flat-button (click)="continue=true">연속재생</button>
                <button *ngIf="continue==true" mat-flat-button (click)="continue=false">연속재생 해제</button>
              </div> -->
          </div>
        </div>

        <!--vod info-->
        <div class="col-lg-4">
          <div class="view view_txt" *ngIf="loading === false">
            <div class="header_txt">
              <h3>{{ this.selected.title }}</h3>
            </div>
            <div class="inner-info">
              <div>
                <img src="../../../../assets/images/clock.svg" />
                <span *ngIf="selected.vod_min == 0"> -- 분</span>
                <span *ngIf="selected.vod_min > 0"> {{ this.selected.vod_min }} 분</span>
              </div>
              <div>
                <img src="../../../../assets/images/star.svg" />
                <img *ngIf="selected.level == 2 || selected.level == 3" src="../../../../assets/images/star.svg" />
                <img *ngIf="selected.level == 3" src="../../../../assets/images/star.svg" />
              </div>
              <div [ngClass]="selected.favorite ? 'favorite-btn' : ''" (click)="myFavorite(selected.id, selected.favorite)" style="background: rgba(255, 255, 255, 0.2); cursor: pointer">
                <img *ngIf="selected.favorite == false" src="../../../../../assets/images/empty-bookmark2.svg" />
                <img *ngIf="selected.favorite == true" src="../../../../assets/images/favorite.svg" />
                즐겨찾기
              </div>
            </div>
            <br /><br /><br />
            <div class="playlist_txt">
              <p *ngIf="selected.gangsa !== ''" class="name_txt">강사 {{ this.selected.gangsa }}쌤</p>
              <p>{{ this.selected.content }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="sort-dropdown">
    <div class="dropdown-btn"><img src="../../../../assets/images/menu.svg" /> 정렬</div>
    <div class="dropdown-content">
      <a (click)="sorting(1)">최신순</a>
      <a (click)="sorting(2)">오래된순</a>
      <a (click)="sorting(3)">랜덤 목록</a>
      <!-- <a (click)="sort_type=2">T-BOX FIT</a>
      <a (click)="sort_type=1">T-CYCLING</a> -->
    </div>
  </div>

  <!-- <div class="sub-btn" (click)="cbDelete(false)">선택 삭제</div>
  <div class="sub-btn" (click)="selectVod()" [ngClass]="selectedPlay ? 'sub-btn-clicked' : ''">선택 재생</div>
  <div class="cbAll-wrap"><input id="cbAll" type="checkbox" (change)="cbAll($event)" /><label for="cbAll"></label><span> 전체 선택</span></div> -->
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="checkbox">
      <td width="5%" mat-cell *matCellDef="let v">
        <div style="float: right"><input id="cb{{ v.id }}" type="checkbox" value="{{ v.id }}" (change)="checkItem()" /><label for="cb{{ v.id }}"></label></div>
      </td>
    </ng-container>
    <ng-container matColumnDef="img">
      <td width="20%" mat-cell *matCellDef="let v" (click)="changeSelectedContent(v)">
        <div *ngIf="loading === false">
          <img class="vod_img" src="{{ v.thumbnail }}" alt="thumbnail" title="{{ v.title }}" />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <td mat-cell *matCellDef="let v" (click)="changeSelectedContent(v)" class="title" style="cursor: pointer">
        <div width="45%" class="table_txt">{{ v.title }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="cate">
      <td width="15%" mat-cell *matCellDef="let v">
        <div *ngIf="v.vod_type == 1" class="table_txt">T-CYCLING</div>
        <div *ngIf="v.vod_type == 2" class="table_txt">T-BOX FIT</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="playability">
      <td width="5%" mat-cell *matCellDef="let v">
        <div>
          <img *ngIf="selected.id != v.id" src="../../../assets/images/playIcon.png" alt="재생 가능" title="재생 가능한 컨텐츠 입니다." />
          <img *ngIf="selected.id == v.id" src="../../../assets/images/playingIcon.png" alt="재생중" title="재생 중인 컨텐츠 입니다." />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="drop">
      <td width="5%" mat-cell *matCellDef="let v">
        <div (click)="cbDelete(v.id)" class="delVod">
          <img id="white" src="../../../assets/images/garbage.svg" alt="삭제" />
          <img id="yellow" src="../../../assets/images/garbage-yellow.svg" alt="삭제" title="즐겨찾기 목록에서 해당 컨텐츠가 삭제됩니다." />
        </div>
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="sort">
      <td width="5%" mat-cell *matCellDef="let v">
        <div>
          <div class="sort-dropdown">
            <div class="dropdown-btn">
              <img src="../../../assets/images/menu_line.svg" alt="순서" />
            </div>
          </div>
        </div>
      </td>
    </ng-container> -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator class="paginator" style="font-size: 15px" [pageSizeOptions]="[5, 10, 15, 20, dataSource?.data.length]" (page)="pageEvents($event)"> </mat-paginator>
</div>
