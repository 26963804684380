import { Renderer2, Component, NgModule, OnInit, Inject } from '@angular/core';
import { KrUserService } from 'src/app/shared/services/kr-user.service';
import { DatePipe } from '@angular/common';
import * as TBM_ROLE from 'src/app/shared';
import { TranslateService } from '@ngx-translate/core';
import { ifError } from 'assert';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { Button } from 'protractor';
import { DOCUMENT } from '@angular/common';
import { s3Url } from 'src/app/shared';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  isAdmin = false;
  items = [];
  itemsSorted = [];

  mySelTabIndex = 1;

  sorts = [
    {value: '1', viewValue: '신상품'},
    {value: '2', viewValue: '신상품 역순'},
    {value: '3', viewValue: '조회수'},
    // {value: '4', viewValue: 'By Alphabetical'},
  ];
  selectedSort = '1';

  routeCate = -1;
  cates = [];
  selectedCate = -1;

  page = 1;
  pageSize = 8;
  myScript;

  constructor(
    private userService: KrUserService,
    private datePipe: DatePipe,
    public tr: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if ( JSON.parse(localStorage.getItem('user'))?.role == 1 ) {
      this.isAdmin = true;
    }
    this.userService.getAllStoreItem().subscribe(
      data => {
        // console.log(data);
        // this.items = data.results[0].results;
        data.results.forEach(sc => {
          sc.results.forEach(si => {
            this.items.push({
              id: si.id,
              name: si.name,
              price: Number(si.price),
              onsale: si.onsale,
              sale_price: si.sale_price,
              // onsale: false,
              // sale_price: 0,
              main_img: s3Url + si.main_img,
              naver_store: si.naver_store,
              interpark_store: si.interpark_store,
              cc: si.click_count,
              cd: this.datePipe.transform(si.create_date, 'short'),
              store_cate_id: sc.store_cate_id,
              store_cate: sc.store_cate
            });
          });
        });
        this.cates = data.extra_data;
        // console.log(this.cates);

        this.route.params.subscribe(
          params => {
            this.routeCate = +params['cate'];
          }
        );
        if(this.routeCate  === null || this.routeCate === undefined ){
          this.location.go('kr/store;cate='+this.cates[0].id);
          this.routeCate = this.cates[0].id;
          this.setFilters('1', this.cates[0].id);
        } else {
          let found = -1;
          for( let i = 0; i < this.cates.length; i ++){
            // console.log(this.routeCate, this.cates[i].id, this.routeCate === this.cates[i].id)
            if( this.routeCate === this.cates[i].id ){
              found = i;
              break;
            }
          }
          if( found === -1 ){
            this.location.go('kr/store;cate='+this.cates[0].id);
            this.routeCate = this.cates[0].id;
          }
          this.setFilters('1', this.cates[0].id);
          this.mySelTabIndex = found + 1;
        }

      }, error => {
        // console.log(error);
      }
    );
  }

  ngOnInit() {
    this.myScript = this._renderer2.createElement('script');
    this.myScript.type = `text/javascript`;
    this.myScript.text = `
      gtag('event', 'conversion', {'send_to': 'AW-741332516/nxdGCMf_j8kDEKSsv-EC'})
    `;
  }

  checkTabChange(e){
    if(e.index !== 0 ){
      this.setFilters(this.selectedSort, this.cates[e.index-1].id);
    }
  }

  setSort(s) {
    if ( s === '1' ) { // By Date New First
      this.itemsSorted = this.itemsSorted.slice();
    } else if ( s === '2' ) { // By Date Old First
      this.itemsSorted = this.itemsSorted.slice().reverse();
    } else if ( s === '3' ) { // By Most Views
      this.itemsSorted = this.itemsSorted.slice().sort((a,b) => b.cc - a.cc );
    }
    this.selectedSort = s;
    // console.log(this.itemsSorted);
  }

  setFilters(s, c){
    if ( s === '1' ) { // By Date New First
      this.itemsSorted = this.items.filter(e => e.store_cate_id === c).slice();
    } else if ( s === '2' ) { // By Date Old First
      this.itemsSorted = this.items.filter(e => e.store_cate_id === c).slice().reverse();
    } else if ( s === '3' ) { // By Most Views
      this.itemsSorted = this.items.filter(e => e.store_cate_id === c).slice().sort((a,b) => b.cc - a.cc );
    }
    this.selectedSort = s;
    // console.log(this.itemsSorted);
    this.location.go('kr/store;cate='+c);
  }

  clickItem(item) {
    this.userService.addStoreItemClick(item.id).subscribe(
      data => {}, error => {}
    );
  }

  naverStoreClicked(item){
    this._renderer2.appendChild(this._document.body, this.myScript);

    this.userService.viewNaverStoreItem(item.id).subscribe(
      data => {}, error => {}
    );
  }

  interparkStoreClicked(item){
    this._renderer2.appendChild(this._document.body, this.myScript);

    this.userService.viewInterparkStoreItem(item.id).subscribe(
      data => {}, error => {}
    );
  }


}
