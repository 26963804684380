<div class="wrap wrap_community">
  <div class="tab_tit" style="color: #212935; justify-content: space-between; align-items: flex-end">
    <div *ngIf="routeType === 'nf' || routeType === null || this.routeType === undefined" class="btn-notice-wrap">
      <button class="btn-notice" style="background-color: #ff375f; font-weight: 900">공지사항</button>
      <button class="btn-notice" style="background-color: #fec40d" (click)="changeCate(1)">HOT<br />NEWS</button>
      <button class="btn-notice" style="background-color: #1badff" (click)="changeCate(2)">FAQ<br />자주질문</button>
    </div>
    <div *ngIf="routeType === 're'" class="btn-notice-wrap">
      <button class="btn-notice" style="background-color: #ff375f" (click)="changeCate(0)">공지사항</button>
      <button class="btn-notice" style="background-color: #fec40d; font-weight: 900">HOT<br />NEWS</button>
      <button class="btn-notice" style="background-color: #1badff" (click)="changeCate(2)">FAQ<br />자주질문</button>
    </div>
    <div *ngIf="routeType === 'faq'" class="btn-notice-wrap">
      <button class="btn-notice" style="background-color: #ff375f" (click)="changeCate(0)">공지사항</button>
      <button class="btn-notice" style="background-color: #fec40d" (click)="changeCate(1)">HOT<br />NEWS</button>
      <button class="btn-notice" style="background-color: #1badff; font-weight: 900">FAQ<br />자주질문</button>
    </div>
  </div>
  <h3 (click)="nav()" style="color: #434343; padding: 3px -10px; font-weight: 300; font-size: 13px; cursor: pointer; float: right">MORE +</h3>
  <table mat-table [dataSource]="seletedCate" class="mat-elevation-z8 body_notice">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 70%">
        <!--{{ 'Title' | translate }}-->
        제목
      </th>
      <td mat-cell *matCellDef="let nf" [ngClass]="'customWidthClass'" style="width: 70%">
        <ng-container *ngIf="nf.pin">
          <span><img src="../../../../assets/images/pin.png" alt="pin" width="15px" style="display: inline; margin-right: 5px" /></span>
        </ng-container>

        {{ nf.title }}
      </td>
    </ng-container>
    <ng-container matColumnDef="cc">
      <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="text-align: center">
        <!--{{ 'Views' | translate }}-->
        조회수
      </th>
      <td mat-cell *matCellDef="let nf" [ngClass]="'customWidthClass'" style="text-align: center">{{ nf.cc }}</td>
    </ng-container>
    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 20%; text-align: center">
        <!--{{ 'Upload Date' | translate }}-->
        작성자
      </th>
      <td mat-cell *matCellDef="let nf" [ngClass]="'customWidthClass'" style="width: 20%; text-align: center"><!--{{ nf.createDate }}-->T-BOX MEDIA</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="newsfeedDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: newsfeedDisplayedColumns" (click)="openNf(row.id)"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 20, 25]" showFirstLastButtons style="display: none"></mat-paginator>
</div>
