import { Injectable } from "@angular/core";
@Injectable()
export class Member {
    loggedIn: boolean;
    id: number;
    fullname: string;
    email: string;
    username: string;
    cellphone: string;
    // password: string;
    // role: Role;
    role: number;
    photoUrl?: string;
    type: string;
    token?: string;
}


export const ERROR: number = 0;
export const SU: number = 1;
export const NONE: number = 2;
export const MEMBER: number = 3;
export const BIZ: number = 4;
export const INSTRUCTOR: number = 5;
export const O2O: number = 6;