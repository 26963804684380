import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Member } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate() {
    if ( localStorage.getItem('token') ) {
      return true;
    }
    let lang = location.pathname.substr(1,2);

    // if ( localStorage.getItem('loginStatus') === 'incomplete' ) {
    //   if( lang === 'kr' ) {
    //     this.router.navigate(['kr/incomplete']);
    //   } else if ( lang === 'en' ){
    //     this.router.navigate(['en/incomplete']);
    //   }
    //   return false;
    // }

    if( lang === 'kr' ) {
      this.router.navigate(['kr/login']);
    } else if ( lang === 'en' ){
      this.router.navigate(['en/login']);
    }
    return false;
}
}
