<div class="wrap">
  <!--로그인-->
  <form class="example-form">
    <img class="logo" src="../../../assets/images/logo_white.png" />

    <mat-form-field class="example-full-width">
      <input matInput type="text" id="user" [(ngModel)]="loginInfo.user" name="user" placeholder="아이디" />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input matInput type="password" id="password" [(ngModel)]="loginInfo.password" name="password" placeholder="비밀번호" />
    </mat-form-field>
    <mat-error class="error" *ngIf="submitted && errorExists"><!--{{ 'ERROR: Please check email and password again' | translate }}-->아이디와 비밀번호를 확인해 주세요.</mat-error>

    <ng-container *ngIf="isAccountDeactivated">
      <app-reactivate-account (reactivate)="handleReactivate()"></app-reactivate-account>
    </ng-container>
    <ng-container *ngIf="isAccountDeleted"> <app-account-deleted></app-account-deleted> </ng-container><br />
    <button class="btn_log" mat-flat-button (click)="loginUser()"><!--{{ 'Confirm' | translate }}-->로그인</button>
    <app-login-options [l_state]="l_state" (l_state_changed)="changeStateHandler($event)"></app-login-options>
    <hr />
    <!-- <app-login-social></app-login-social> -->
    <!-- <div class="g-signin2" data-onsuccess="onSignin" (click)="signin()"></div> -->
    <!-- <div id="buttonDiv" ></div>
    <ul>
      <li (click)="kakaoLogin()">
          <a href="javascript:void(0)">
              <span>카카오 로그인</span>
          </a>
      </li>
      <li (click)="kakaoLogout()">
          <a href="javascript:void(0)">
              <span>카카오 로그아웃</span>
          </a>
      </li>
    </ul> -->
  </form>
  <br />
</div>
<br /><br />
