<div class="wrap">
  <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selected">
    <mat-tab label="회사소개">
      <div class="tab_tit">
        <!-- {{ 'About Us' | translate }} -->
        <h3>플랫폼 소개</h3>
      </div>

      <div class="row">
        <div class="col-md-12">
          <img src="../../../../../assets/images/about/001.png" />
          <img src="../../../../../assets/images/about/002.png" />
          <img src="../../../../../assets/images/about/003.png" />
          <img src="../../../../../assets/images/about/004.png" />
          <img src="../../../../../assets/images/about/005.png" />
          <img src="../../../../../assets/images/about/006.png" />
          <img src="../../../../../assets/images/about/007.png" />
          <img src="../../../../../assets/images/about/008.png" />
        </div>
      </div>
    </mat-tab>
    <!-- <mat-tab label="콘텐츠 소개"></mat-tab>
    <mat-tab label="에듀테크"></mat-tab>
    <mat-tab label="IoT 디바이스"> </mat-tab> -->
  </mat-tab-group>
</div>
