import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeKo from "@angular/common/locales/ko";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { FlatpickrModule } from "angularx-flatpickr";
import { ngbModule } from "src/ngb-module";
import { MaterialModule } from "src/material-module";
import { register } from "swiper/element/bundle";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard, Member, QuizIncompleteGuard, LessonIncompleteGuard } from "./shared";
import { PendingChangesGuard } from "./shared/guards/pending-changes.guard";
import { NoPageFoundComponent } from "./no-page-found/no-page-found.component";

import { HeaderComponent } from "./layout-kr/header/header.component";
import { FooterComponent } from "./layout-kr/footer/footer.component";
import { BodyComponent } from "./layout-kr/components/body/body.component";
import { AboutUsComponent } from "./layout-kr/components/info/about-us/about-us.component";
import { MembershipTermsComponent } from "./layout-kr/components/info/membership-terms/membership-terms.component";
import { PrivacyPolicyComponent } from "./layout-kr/components/info/privacy-policy/privacy-policy.component";
import { TermsOfServiceComponent } from "./layout-kr/components/info/terms-of-service/terms-of-service.component";

import { SignupComponent } from "./layout-kr/components/signup/signup.component";
import { AccountDeletedComponent } from "./layout-kr/components/delete-account/account-deleted/account-deleted.component";
import { ReactivateAccountComponent } from "./layout-kr/components/delete-account/reactivate-account/reactivate-account.component";
import { LoginComponent, LoginRemindIDComponent, LoginResetPWComponent, LoginRegularComponent, LoginOptionsComponent } from "./layout-kr/components/login/login.component";
import {
  MultiprofileComponent,
  MultiprofileRegularComponent,
  MultiprofileNewprofileComponent,
  MultiprofileEditComponent,
  MultiprofileDeleteComponent
} from "./layout-kr/components/multiprofile/multiprofile.component";
import { ProfileComponent, VodViewLogComponent, ProfileFindJusoComponent, ProfileSubHistory, ProfileMySubscription, SubTerminationPopup, Favorite } from "./layout-kr/components/user/profile/profile.component";
import { SetupComponent } from "./layout-kr/components/user/setup/setup.component";

import { SubscriptionComponent } from "./layout-kr/components/subscription/subscription.component";
import { StoreComponent } from "./layout-kr/components/store/store.component";
import { VodComponent } from "./layout-kr/components/vod/vod.component";
import { CommunityComponent, CommunityViewerComponent, CommunityNoticeComponent } from "./layout-kr/components/community/community.component";

import { TboxComponent } from "./layout-kr/components/tbox/tbox.component";
import { EventComponent } from "./layout-kr/components/event/event.component";

registerLocaleData(localeKo, "ko");

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

register();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BodyComponent,

    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    MembershipTermsComponent,
    NoPageFoundComponent,

    SignupComponent,
    LoginComponent,
    LoginRegularComponent,
    LoginRemindIDComponent,
    LoginResetPWComponent,
    LoginOptionsComponent,
    MultiprofileComponent,
    MultiprofileRegularComponent,
    MultiprofileNewprofileComponent,
    MultiprofileEditComponent,
    MultiprofileDeleteComponent,
    ProfileComponent,
    ProfileFindJusoComponent,
    ProfileSubHistory,
    ProfileMySubscription,
    VodViewLogComponent,
    Favorite,
    AccountDeletedComponent,
    ReactivateAccountComponent,
    SetupComponent,

    SubscriptionComponent,
    SubTerminationPopup,
    StoreComponent,
    VodComponent,
    CommunityComponent,
    CommunityViewerComponent,
    CommunityNoticeComponent,

    TboxComponent,
    EventComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CommonModule,
    FlatpickrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ngbModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [AppComponent],
  providers: [AuthGuard, Member, DatePipe, PendingChangesGuard, QuizIncompleteGuard, LessonIncompleteGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
