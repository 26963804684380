<div class="page404">
  <div class="error-template">
    <h1>Oops!</h1>
    <h2>404 Not Found</h2>
    <div class="error-details mb-3">
      <h3>Sorry, Requested page not found!</h3>
    </div>
    <div class="error-actions">
      <button mat-stroked-button class="btn btn-danger btn-lg" (click)="redirectHome()">HOME</button>
    </div>
  </div>
</div>