import { Renderer2, Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { MustMatch } from './must-match.validator';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  errorExists = false;
  checkbox = false;
  checkUsernameText = "아이디 중복 확인";
  checkUsernameDup;

  youtubeSubImg = null;

  myScript;

  constructor( 
    private formBuilder: FormBuilder,
    public router: Router,
    private userService: UserService,
    // private member: Member
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document
  ) { 
  }

  ngOnInit() {
    this.myScript = this._renderer2.createElement('script');
    this.myScript.type = `text/javascript`;
    this.myScript.text = `
      gtag('event', 'conversion', {'send_to': 'AW-741332516/CviBCP_h2okCEKSsv-EC'})
    `;


    this.errorExists = false;
    this.registerForm = this.formBuilder.group({
        fullname: ['', Validators.required],
        username: ['', Validators.required],
        usernameCheckedUnique: [false, Validators.requiredTrue],
        password: ['', [
          Validators.required, 
          Validators.minLength(8),
          Validators.pattern(/(?!.*pass|.*word|.*1234|.*qwer|.*asdf|.*abc|.*aaaa|.*000|.*111|.*222|.*333|.*444|.*555|.*666|.*777|.*888|.*999|.*123|.*012)^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[`~!@#\$%\^&*\-_=\+\|\\<>?]){1,})([a-zA-Z0-9\`~!@#\$%\^&\*\-_=+|\\<>/?]+)$/)
          /*
            /^
            Prevent common passwords (THIS MUST COME FIRST)
            (?!.*pass|.*word|.*1234|.*qwer|.*asdf|.*abc|.*aaaa|.*000|.*111|.*222|.*333|.*444|.*555|.*666|.*777|.*888|.*999|.*123|.*012)
            Find a number
            (?=(.*[0-9]){1,})
            Find a lowercase
            (?=(.*[a-z]){1,})
            Find an uppercase
            (?=(.*[A-Z]){1,})
            Find a special character
            (?=(.*[`~!@#\$%\^&*\-_=\+\|\\<>?]){1,})
            possible fillers
            ([a-zA-Z0-9\`~!@#\$%\^&\*\-_=+|\\<>/?]+)
            $/
          */
        ]],
        cpassword: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        // cellphone: ['', Validators.required],
        // telephone: ['', ],
        cnum1: ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(3), Validators.maxLength(3)]],
        cnum2: ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(4)]],
        cnum3: ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(4)]],
        // tnum1: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(2), Validators.maxLength(3)]],
        // tnum2: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(3), Validators.maxLength(4)]],
        // tnum3: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(4)]],
        // address: ['', Validators.required],
        // youtubeSubImg: [null, Validators.required],
        acceptTerms: [false, Validators.requiredTrue]

    }, {validator: MustMatch('password', 'cpassword')});
    
  }

  get rf() { return this.registerForm.controls; }

  changeCheckbox(){
    this.checkbox = !this.checkbox;
  }

  checkUsernameUnique(){
    // this.registerForm.value.username;
    if( this.registerForm.value.username.length < 6  ){
      alert("아이디가 너무 짧습니다");
    } else {
      this.checkUsernameText = "확인중..."
      this.userService.vaildUsername(this.registerForm.value.username).subscribe(
        data => {
          this.registerForm.get('usernameCheckedUnique').setValue(true);
          alert( "사용 가능한 아이디 입니다." );
          this.checkUsernameDup = this.registerForm.value.username
        }, error => {
          if (error.status == 400) {
            this.registerForm.get('usernameCheckedUnique').setValue(false);
            alert( "이미 사용중인 아이디 입니다. 다른 아이디를 입력해 주세요.")
          }
        }, () => {
          this.checkUsernameText = "아이디 중복 확인";
        }
      )
    }
  }

  fileUpload(e){
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.youtubeSubImg = file;
    }
  }

  signupUser = () => {
    this.submitted = true;
    if( this.checkUsernameDup !== this.registerForm.value.username ){
      alert( "아이디 중복 확인을 해주세요." );
    } 
    // else if ( this.youtubeSubImg === null ) {
    //   alert("You must upload an image");
    // }
    else {
      if ( this.registerForm.valid ) {
        this.userService.signup(this.registerForm).subscribe(
          data => {
            // alert("");
            // this._renderer2.appendChild(this._document.body, this.myScript);

            this.router.navigate(['kr/login']).then(()=>{
              alert('정상적으로 가입이 완료되었습니다');
            });
    
          }, error => {
            if (error.status == 400) {
              this.errorExists = true;
            }
          }
        );
      } else {
        this.errorExists = true;
        // alert("asdf");
        // alert("fullname " + this.registerForm.get('fullname').hasError('required'))
        // alert("email " + this.registerForm.get('email').hasError('required'))
        // alert("username " + this.registerForm.get('username').hasError('required'))
        // alert("password " + this.registerForm.get('password').hasError('required'))
        // alert("cpassword " + this.registerForm.get('cpassword').hasError('required'))
        // alert("cnum1 " + this.registerForm.get('cnum1').hasError('required'))
        // alert("cnum2 " + this.registerForm.get('cnum2').hasError('required'))
        // alert("cnum3 " + this.registerForm.get('cnum3').hasError('required'))
        // alert("tnum1 " + this.registerForm.get('tnum1').hasError('required'))
        // alert("tnum2 " + this.registerForm.get('tnum2').hasError('required'))
        // alert("tnum3 " + this.registerForm.get('tnum3').hasError('required'))
        // alert("address " + this.registerForm.get('address').hasError('required'))
      }
    }

  }

}
