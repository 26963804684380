import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './pending-changes.guard';

@Injectable({
  providedIn: 'root'
})
export class QuizIncompleteGuard implements CanDeactivate<ComponentCanDeactivate> {

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {

    return component.canDeactivate() 
    
    // return component.canDeactivate() ?
      // true :
      // // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // // when navigating away from your angular app, the browser will show a generic warning message
      // // see http://stackoverflow.com/a/42207299/7307355
      // confirm( "WARNING: You have not completed the quiz. Would you still like to leave?" );
      
  }
  
}
