<div class="wrap">
  <div class="container">
    <div class="row">
      <div class="admin-sub-menu">
        <ul>
          <a href="/kr/admin/main">메인</a>
          <a href="/kr/admin/msns">미션</a>
          <a href="/kr/admin/pers">인원</a>
        </ul>
      </div>"

      <div class="mypage-header">
          <h3>마이 페이지</h3>
          <p>회원님의 각종 정보를 확인 및 수정이 가능한 페이지입니다.</p>
      </div>
  </div>
</div>
<div class="body_bg"></div>
