<div class="wrap wrap_community">
  <div *ngIf="routeType === 'nf' || routeType === null || this.routeType === undefined" class="community_nav">
    <button style="color: #fec40d">공지사항</button>
    <button (click)="nav('faq')">FAQ</button>
    <button (click)="nav('re')">NEWS</button>
    <!-- <button (click)="nav('vc')">스케줄</button> -->
  </div>
  <div *ngIf="routeType === 'faq'" class="community_nav">
    <button (click)="nav('nf')">공지사항</button>
    <button style="color: #fec40d">FAQ</button>
    <button (click)="nav('re')">NEWS</button>
    <!-- <button (click)="nav('vc')">스케줄</button> -->
  </div>
  <div *ngIf="routeType === 're'" class="community_nav">
    <button (click)="nav('nf')">공지사항</button>
    <button (click)="nav('faq')">FAQ</button>
    <button style="color: #fec40d">NEWS</button>
    <!-- <button (click)="nav('vc')">스케줄</button> -->
  </div>
  <!-- <div *ngIf="routeType === 'vc'" class="community_nav">ng sser
    <button (click)="nav('nf')">공지사항</button>
    <button (click)="nav('faq')">FAQ</button>
    <button (click)="nav('re')">NEWS</button> -->
  <!-- <button style="color: #fec40d">스케줄</button> -->
  <!-- </div> -->

  <div *ngIf="routeType === 'nf' || routeType === null || this.routeType === undefined">
    <div class="tab_tit">
      <h3>COMMUNITY</h3>
      <i class="material-icons">chevron_right</i>
      <h3><!--{{ 'Notice' | translate }}-->공지사항</h3>
    </div>

    <app-community-viewer *ngIf="sendinfo.send === 'nf'" [title]="sendinfo.title" [body]="sendinfo.body" (close)="close()"></app-community-viewer>

    <mat-form-field>
      <!-- {{ 'Filter' | translate }} -->
      <input matInput (keyup)="applyNewsfeedFilter($event.target.value)" placeholder="검색" />
    </mat-form-field>
    <i class="material-icons search-icon" style="color: #343434">search</i>

    <table mat-table [dataSource]="newsfeedDataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 70%">
          <!--{{ 'Title' | translate }}-->
          제목
        </th>
        <td mat-cell *matCellDef="let nf" [ngClass]="'customWidthClass'" style="width: 70%" class="scrTop">
          <ng-container *ngIf="nf.pin">
            <span><img src="../../../../assets/images/pin.png" alt="pin" width="15px" style="display: inline; margin-right: 5px" /></span>
          </ng-container>
          {{ nf.title }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef> {{ 'Body' | translate }} </th>
        <td mat-cell *matCellDef="let ban"> {{ ban.caption_sub }} </td>
      </ng-container> -->
      <ng-container matColumnDef="cc">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="text-align: center">
          <!--{{ 'Views' | translate }}-->
          조회수
        </th>
        <td mat-cell *matCellDef="let nf" [ngClass]="'customWidthClass'" style="text-align: center">{{ nf.cc }}</td>
      </ng-container>
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 20%; text-align: center">
          <!--{{ 'Upload Date' | translate }}-->
          작성자
        </th>
        <td mat-cell *matCellDef="let nf" [ngClass]="'customWidthClass'" style="width: 20%; text-align: center"><!--{{ nf.createDate }}-->T-BOX MEDIA</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="newsfeedDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: newsfeedDisplayedColumns" (click)="openViewer('nf', row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 25]" showFirstLastButtons></mat-paginator>

  </div>

  <div *ngIf="routeType === 'faq'">
    <div class="tab_tit">
      <h3>COMMUNITY</h3>
      <i class="material-icons">chevron_right</i>
      <h3><!--{{ 'FAQ' | translate }}-->FAQ</h3>
    </div>
    <app-community-viewer *ngIf="sendinfo.send === 'faq'" [title]="sendinfo.title" [body]="sendinfo.body" (close)="close()"></app-community-viewer>
    <mat-form-field>
      <!-- {{ 'Filter' | translate }} -->
      <input matInput (keyup)="applyFAQFilter($event.target.value)" placeholder="검색" />
    </mat-form-field>
    <i class="material-icons search-icon" style="color: #343434">search</i>

    <table mat-table [dataSource]="faqDataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 65%"><!--{{ 'Question' | translate }}-->질문</th>
        <td mat-cell *matCellDef="let faq" [ngClass]="'customWidthClass'" style="width: 65%" class="scrTop">{{ faq.question }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef> {{ 'Body' | translate }} </th>
        <td mat-cell *matCellDef="let ban"> {{ ban.caption_sub }} </td>
      </ng-container> -->
      <ng-container matColumnDef="cc">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="text-align: center"><!--{{ 'Views' | translate }}-->조회수</th>
        <td mat-cell *matCellDef="let faq" [ngClass]="'customWidthClass'" style="text-align: center">{{ faq.cc }}</td>
      </ng-container>
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 20%; text-align: center">
          <!--{{ 'Upload Date' | translate }}-->
          작성자
        </th>
        <td mat-cell *matCellDef="let faq" [ngClass]="'customWidthClass'" style="width: 20%; text-align: center"><!--{{ faq.createDate }}-->T-BOX MEDIA</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="faqDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: faqDisplayedColumns" (click)="openViewer('faq', row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 25]" showFirstLastButtons></mat-paginator>

  </div>
  <div *ngIf="routeType === 're'">
    <div class="tab_tit">
      <h3>COMMUNITY</h3>
      <i class="material-icons">chevron_right</i>
      <h3><!--{{ 'Resources' | translate }}-->NEWS</h3>
    </div>
    <app-community-viewer *ngIf="sendinfo.send === 're'" [title]="sendinfo.title" [body]="sendinfo.body" (close)="close()"></app-community-viewer>

    <!--등급선택(개인,비즈,강사)-->
    <!-- <mat-button-toggle-group #roleTabs="matButtonToggleGroup" value="mem" class="group_tab"> -->
    <!-- <mat-button-toggle value="mem" (click)="changeResourceDataSource('mem')"> -->
    <!-- 회원 -->
    <!-- </mat-button-toggle> -->
    <!-- <mat-button-toggle value="biz" (click)="changeResourceDataSource('biz')"> -->
    <!-- 비즈 -->
    <!-- </mat-button-toggle> -->
    <!-- <mat-button-toggle value="ins" (click)="changeResourceDataSource('ins')"> -->
    <!-- 강사 -->
    <!-- </mat-button-toggle> -->
    <!-- </mat-button-toggle-group> -->

    <table mat-table [dataSource]="selectedReDataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 70%">
          <!--{{ 'Title' | translate }}-->
          제목
        </th>
        <td mat-cell *matCellDef="let re" [ngClass]="'customWidthClass'" style="width: 70%" class="scrTop">{{ re.title }}</td>
      </ng-container>
      <ng-container matColumnDef="cc">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="text-align: center"><!--{{ 'Views' | translate }}-->조회수</th>
        <td mat-cell *matCellDef="let re" [ngClass]="'customWidthClass'" style="text-align: center">{{ re.cc }}</td>
      </ng-container>
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 20%; text-align: center">
          <!--{{ 'Upload Date' | translate }}-->
          작성자
        </th>
        <td mat-cell *matCellDef="let re" [ngClass]="'customWidthClass'" style="width: 20%; text-align: center"><!--{{ re.createDate }}-->T-BOX MEDIA</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="resourceDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: resourceDisplayedColumns" (click)="openViewer('re', row)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 25]" showFirstLastButtons></mat-paginator>

  </div>
  <!-- <div *ngIf="routeType === 'vc'">
    <div class="tab_tit">
      <h3>COMMUNITY</h3>
      <i class="material-icons">chevron_right</i>
      <h3>스케줄</h3>
    </div>

    <app-community-vod-cal></app-community-vod-cal>
  </div> -->
</div>
