<div class="wrap">
  <img class="logo" src="../../../assets/images/logo.png"/>
  <h2>추가정보입력</h2>

  <br>
  <mat-form-field class="full-width">
    <input matInput type="text" [(ngModel)]="userInfo.fullname" placeholder="이름">
  </mat-form-field><br><br>
  
  <mat-form-field class="full-width">
    <input matInput type="text" [(ngModel)]="userInfo.email" placeholder="이메일">
  </mat-form-field><br><br>

  <mat-form-field class="full-width">
    <input matInput type="text" [(ngModel)]="userInfo.username" placeholder="아이디">
  </mat-form-field><br><br>
  <div *ngIf="checkUsernameDup !== userInfo.username">
    <button mat-raised-button type="button"(click)="checkUsernameUnique()" style="background-color: #eceef0;">{{ checkUsernameText }}</button>
  </div>
  <div *ngIf="checkUsernameDup === userInfo.username">
    <label>사용가능한 아이디 입니다.</label>
  </div>

  <table class="full-width" cellspacing="0">
    <tr style="display: flex; align-items: center;">
      <td style="width: 40%;"> 
        <mat-form-field class="full-width">
          <input matInput type="text" [(ngModel)]="userInfo.cnum1" placeholder="휴대폰 번호">
        </mat-form-field>
      </td>-
      <td style="width: 30%;">
        <mat-form-field class="full-width" style="text-align: center;">
          <input matInput type="text" [(ngModel)]="userInfo.cnum2" placeholder="XXXX">
        </mat-form-field>
      </td>-
      <td style="width: 30%;">
        <mat-form-field class="full-width" style="text-align: center;">
          <input matInput type="text" [(ngModel)]="userInfo.cnum3" placeholder="XXXX">
        </mat-form-field>
      </td>
    </tr>
  </table>
  <br>
  
  <mat-checkbox (change)="changeCheckbox($event)">
    본인은 T-BOX MEDIA 서비스 약관, 개인정보 보호 정책 및 회원 약관
    <a routerLink="/tos" target="_blank">사이트이용약관</a>,
    <a routerLink="/privacy" target="_blank">개인정보처리방침</a>, 
    <a routerLink="/membership-terms" target="_blank">멤버십 약관</a>
    을 읽고 동의 함을 확인합니다.  
  </mat-checkbox>
  
  <br>
  <hr>

  <br>
  <button mat-raised-button class="btn_log" (click)="updateUser()">
    가입완료
  </button>


</div>
