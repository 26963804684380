import { Component, OnInit } from '@angular/core';
import { Bootpay } from '@bootpay/client-js';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  subscription = [];
  constructor(
    public router: Router,
    private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {

    // Start calling subscription api
    this.subscriptionService.getSubscriptionList().subscribe(
      data => {
        this.subscription = data;
      },
      error => {
        // console.log(error);
      }
    );

    // get current info

  }

  selectSub() {

  }

  canPurchase() {

  }

  purchaseSub() {

  }

  saveBillingKey() {
  }

  requestBillingKey(sub) {
    if(!localStorage.getItem('token')){
      alert('로그인 후 이용권을 구매할 수 있습니다.');
      this.router.navigate(['kr/login']);
    }
    else {
      if(localStorage.getItem('subscription') != 'null'){
        alert('이미 이용권을 이용 중입니다.');
      }
      // else if(!JSON.parse(localStorage.getItem('address')))
      // {
      //   alert('주소를 입력해주세요.');
      //   this.router.navigate(['kr/profile']);
      // }
      // else if(!JSON.parse(localStorage.getItem('address'))?.roadAddr ){
      //   alert('주소를 입력해주세요.');
      //   this.router.navigate(['kr/profile']);
      // }
      else {
        let order_id = String(new Date().getTime())+String(sub.id)+String(JSON.parse(localStorage.getItem('user'))?.id)
        
        Bootpay.requestSubscription({
          application_id: '626a5f2e2701800020f696d5',
          pg: '나이스페이',
          price: sub.price,
          tax_free: 0,
          order_name: sub.name,
          subscription_id: order_id,
          user: {
            username: JSON.parse(localStorage.getItem('user'))?.fullname,
            email: JSON.parse(localStorage.getItem('user'))?.email
          },
          extra: {
            subscription_comment: `매월 ${sub.price}원이 결제됩니다`,
            subscribe_test_payment: true,
            first_subscription_comment: `${sub.price}원이 결제됩니다`,
          },
          method: '카드자동'
        }).then(
          function (response) {
              if (response.event === 'done') {
                $.ajax({
                  url:environment.apiUrl + "/subscription/billing_key/regist",
                  method:"post",
                  headers: {
                    'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
                  },
                  data: {
                    'receipt_id': response.data['receipt_id'],
                    'subscription_id': sub.id
                  },
                  success:function(data){
                    const userInfo = JSON.parse(localStorage.getItem('user'));
                    userInfo.role = data.role;
                    localStorage.setItem('user', JSON.stringify(userInfo));
                    localStorage.setItem('subscription', sub.id);
                    alert('구독이 완료되었습니다.')
                    location.reload();
                  },
                  error:function(){
                    alert('이용권 결제를 실패했습니다.')
                  }
                });
              }
          },
          function (error) {
            alert('이용권 결제를 실패했습니다.')
          }
        )

      }
    }
  }
}