<div class="wrap">
  <img class="logo" src="../../../assets/images/logo.png"/>
  <!-- <h2>{{ 'Register' | translate }}</h2> -->
  <h2>회원가입</h2>
  <form class="example-form" [formGroup]="registerForm" (ngSubmit)="signupUser()">
    <div *ngIf="errorExists">
      <h4>오류 : 정보를 다시 확인해주세요</h4>
      <!-- <h4>{{ 'ERROR: Please check information again' | translate }}</h4> -->
    </div>

    <!--간단한 이미지-->
    <div>
      <img src="../../../../assets/images/signup_img.jpg" style="width: 100%; border-radius: 10px; margin-bottom: 20px;">
      <!-- <input type="file" (change)="fileUpload($event)" accept=".png, .jpg, .jpeg">
      <mat-error *ngIf="submitted && youtubeSubImg===null" >
        이미지를 업로드해야합니다
      </mat-error> -->
    </div>
    <!-- <mat-error *ngIf="submitted && registerForm.get('youtubeSubImg').hasError('required')" >
      이미지를 업로드해야합니다
    </mat-error><br> -->


    <mat-form-field class="example-full-width">
      <input matInput type="text" formControlName="fullname" placeholder="이름" required>
      <mat-error *ngIf="registerForm.get('fullname').hasError('required')" class="error">
        이름을 입력해 주세요
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput type="email" formControlName="email" placeholder="이메일" required>
      <mat-error *ngIf="registerForm.get('email').hasError('required')" class="error">이메일을 입력해 주세요</mat-error>
      <mat-error *ngIf="registerForm.get('email').hasError('email')" class="error">유효한 이메일을 입력해 주세요</mat-error>
    </mat-form-field>


    <mat-form-field class="example-full-width">
      <input matInput type="text" formControlName="username" placeholder="아이디" required>
      <mat-error *ngIf="registerForm.get('username').hasError('required')" class="error">아이디를 입력해 주세요</mat-error>
    </mat-form-field>
    <!-- TODO Make inline with username-->
    <div *ngIf="checkUsernameDup !== registerForm.value.username">
      <button mat-raised-button type="button"(click)="checkUsernameUnique()" style="background-color: #eceef0; margin-bottom: 20px;">{{ checkUsernameText }}</button>
    </div>
    <div *ngIf="checkUsernameDup === registerForm.value.username">
      <label>사용가능한 아이디 입니다.</label>
    </div>

    <mat-form-field class="example-full-width-password">
      <input matInput type="password" formControlName="password" placeholder="비밀번호" required>
      <mat-error *ngIf="registerForm.get('password').hasError('required')" class="error">
        비밀번호를 입력해 주세요
      </mat-error>
      <mat-error *ngIf="registerForm.get('password').hasError('minlength')" class="error">
        "비밀번호는 8자리 이상이어야 합니다
      </mat-error>
      <mat-error *ngIf="registerForm.get('password').hasError('pattern')" class="error">
        "비밀번호는 알파벳 대문자와 소문자, 특수문자, 숫자 있어야 하고 단순한 비밀번호(예:abc1234) 설정이 불가능합니다
      </mat-error>
    </mat-form-field>


    <mat-form-field class="example-full-width">
      <input matInput type="password" formControlName="cpassword" placeholder="비밀번호 확인" required>
      <mat-error *ngIf="registerForm.get('cpassword').hasError('required')" class="error">
        비밀번호를 다시한번 입력해 주세요
      </mat-error>
      <mat-error *ngIf="registerForm.get('cpassword').hasError('mustMatch')" >
        비밀번호가 일치하지 않습니다
      </mat-error>
    </mat-form-field>

    <table class="example-full-width" cellspacing="0">
      <tr style="display: flex; align-items: center; max-width: 400px; place-content: space-between;">
        <td style="width: 35%;">
          <mat-form-field class="example-full-width">
            <input matInput type="text" formControlName="cnum1" placeholder="휴대폰 번호">
          </mat-form-field>
        </td>
        <p> - </p>
        <td style="width: 25%;">
          <mat-form-field class="example-full-width" style="text-align: center;">
            <input matInput type="text" formControlName="cnum2" placeholder="XXXX">
          </mat-form-field>
        </td>
        <p> - </p>
        <td style="width: 25%;">
          <mat-form-field class="example-full-width" style="text-align: center;">
            <input matInput type="text" formControlName="cnum3" placeholder="XXXX">
          </mat-form-field>
        </td>
      </tr>
    </table>

    <mat-error class="error"
    *ngIf="submitted && ( registerForm.get('cnum1').hasError('required')
          || registerForm.get('cnum1').hasError('minlength')
          || registerForm.get('cnum1').hasError('maxlength')
          || registerForm.get('cnum2').hasError('required')
          || registerForm.get('cnum2').hasError('minlength')
          || registerForm.get('cnum2').hasError('maxlength')
          || registerForm.get('cnum3').hasError('required')
          || registerForm.get('cnum3').hasError('minlength')
          || registerForm.get('cnum3').hasError('maxlength')  )">
          휴대폰 번호의 형식이 잘못되었습니다
    </mat-error>

    <br>
    <mat-checkbox formControlName="acceptTerms" (click)="changeCheckbox()" (keyup.space)="changeCheckbox()">
      본인은 T-BOX MEDIA 서비스 약관, 개인정보 보호 정책 및 회원 약관
      <a routerLink="/kr/tos" target="_blank">사이트이용약관</a>,
      <a routerLink="/kr/privacy" target="_blank">개인정보처리방침</a>,
      <a routerLink="/kr/membership-terms" target="_blank">멤버십 약관</a>
      을 읽고 동의 함을 확인합니다.
    </mat-checkbox>
    <mat-error class="error" *ngIf="submitted && checkbox===false">
        <!-- *ngIf="submitted && registerForm.get('acceptTerms').hasError('requiredTrue')" -->
        약관에 동의해야 합니다
    </mat-error>

    <!-- <table class="example-full-width" cellspacing="0"><tr>
      <td><mat-form-field class="example-full-width">
        <input matInput type="text" formControlName="tnum1" placeholder="{{ 'Telephone Number' | translate }}">
      </mat-form-field></td>
      <td><mat-form-field class="example-full-width">
        <input matInput type="text" formControlName="tnum2" placeholder="-">
      </mat-form-field></td>
      <td><mat-form-field class="example-full-width">
        <input matInput type="text" formControlName="tnum3" placeholder="-">
      </mat-form-field></td>
    </tr></table>

    <mat-error class="error"
    *ngIf="submitted &&
          (  registerForm.get('tnum1').hasError('required')
          || registerForm.get('tnum1').hasError('minlength')
          || registerForm.get('tnum1').hasError('maxlength')
          || registerForm.get('tnum2').hasError('required')
          || registerForm.get('tnum2').hasError('minlength')
          || registerForm.get('tnum2').hasError('maxlength')
          || registerForm.get('tnum3').hasError('required')
          || registerForm.get('tnum3').hasError('minlength')
          || registerForm.get('tnum3').hasError('maxlength') ) ">
          {{ 'Telephone number is incorrectly formatted' | translate }}
    </mat-error> -->

    <!-- <mat-form-field class="example-full-width">
      <input matInput type="text" formControlName="address" placeholder="{{ 'Address' | translate }}">
      <mat-error *ngIf="submitted && registerForm.get('address').hasError('required')" class="error">{{ 'Address is required' | translate }}</mat-error>
    </mat-form-field> -->

    <br><br><br>
    <button mat-raised-button class="btn_log">확인</button>
    <a class="btn_reg" routerLink="/kr/login">로그인</a>
  </form>
</div>
