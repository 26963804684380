<div class="popup_content">
  <img src="../../../assets/images/profile_membershipwaringimg.svg" />
  <p style="color: #000;">정말 해지하시겠어요?</p>
  <p style="color: #000;">
    <span>지금 해지하시면 이용권은</span>
    <br />{{ data.endDate??'-' }} 까지<br />
    이용 가능합니다.
  </p>
</div>
<mat-dialog-actions class="popup_button_wrap">
  <button mat-button class="cancel_button" (click)="onCancelClick()">이용권 유지</button>
  <button mat-button class="success_button" (click)="onConfirmClick()" cdkFocusInitial>해지하기</button>
</mat-dialog-actions>
