<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 my_pass">
  <ng-container matColumnDef="status">
    <tr>
      <th mat-header-cell *matHeaderCellDef>상태</th>
      <td mat-cell *matCellDef="let v">{{ v.status }}</td>
    </tr>
  </ng-container>

  <ng-container matColumnDef="cardCompany">
    <th mat-header-cell *matHeaderCellDef><span style="margin-right: 3px">결제</span><span>카드사</span></th>
    <td mat-cell *matCellDef="let v">{{ v.cardCompany }}</td>
  </ng-container>

  <ng-container matColumnDef="cardNo">
    <th mat-header-cell *matHeaderCellDef><span style="margin-right: 3px">결제</span><span>카드번호</span></th>
    <td mat-cell *matCellDef="let v">{{ v.cardNo }}</td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef>가격</th>
    <td mat-cell *matCellDef="let v">{{ v.price }}</td>
  </ng-container>

  <ng-container matColumnDef="purchasedAt">
    <th mat-header-cell *matHeaderCellDef>결제일</th>
    <td mat-cell *matCellDef="let v">{{ v.purchasedAt }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
