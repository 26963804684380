import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-page-found',
  templateUrl: './no-page-found.component.html',
  styleUrls: ['./no-page-found.component.css']
})
export class NoPageFoundComponent implements OnInit {
  lang = "";
  constructor( public router: Router ) { 
    this.lang = location.pathname.substr(1,2);
    if( this.lang === "" ){
      this.router.navigate(['kr/home']);
    }
  }

  ngOnInit() {
  }

  redirectHome(){
    this.router.navigate(['kr/home']);
  }

}
