<div class="wrap">
  <mat-tab-group mat-align-tabs="center" #tabs (selectedTabChange)="changeSelectedCategory($event)" dynamicHeight animationDuration="0ms">
    <mat-tab *ngFor="let vodCategory of vodCategoryList" label="{{ vodCategory.name }}">
      <div class="tab_tit">
         <h3>{{this.isTboxVod ? 'T-BOX' : 'T-CYCLING '}}</h3>
        <i class="material-icons">&#xe5cc;</i>
        <h3>{{ vodCategory.name }}</h3>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="container">
    <div class="row">
      <!--VOD view-->
      <ng-template [ngIf]="open">
        <div class="col-lg-8" style="padding: 0">
          <div class="view view_vod">
            <div class="view_box">
              <!--loading spinner-->
              <div [hidden]="!loading" class="wrap_spin">
                <mat-spinner diameter="50" class="custom-spinner" style="margin: 0 auto; position: absolute; bottom: 10%; left: calc(50% - 25px)"></mat-spinner>
              </div>
              <div [hidden]="loading" class="video-container">
                <!-- main Vimeo player embed -->
                <div #myVideo></div>
              </div>
            </div>
            <div class="btn_box">
              <button *ngIf="!continue" mat-flat-button (click)="continue = true">연속재생</button>
              <button *ngIf="continue" mat-flat-button (click)="continue = false">연속재생 해제</button>
            </div>
          </div>
        </div>

        <!--VOD info-->
        <div class="col-lg-4">
          <div class="view view_txt" *ngIf="!loading">
            <div class="header_txt">
              <h3>{{ this.selectedVod.title }}</h3>
            </div>
            <div class="inner-info">
              <div>
                <img src="../../../../assets/images/timer.svg" />
                <span *ngIf="selectedVod.vod_min == 0"> -- 분</span>
                <span *ngIf="selectedVod.vod_min > 0"> {{ this.selectedVod.vod_min }} 분</span>
              </div>
              <div>
                <img src="../../../../assets/images/star.svg" />
                <img *ngIf="selectedVod.level == 2 || selectedVod.level == 3" src="../../../../assets/images/star.svg" />
                <img *ngIf="selectedVod.level == 3" src="../../../../assets/images/star.svg" />
              </div>
              <div [ngClass]="selectedVod.favorite ? 'favorite-btn' : ''" (click)="toggleFavorite(selectedVod.id, selectedVod.favorite)" style="background: rgba(255, 255, 255, 0.2); cursor: pointer">
                <img *ngIf="selectedVod.favorite == false" src="../../../../assets/images/empty-favorite.svg" />
                <img *ngIf="selectedVod.favorite == true" src="../../../../assets/images/favorite.svg" />
                즐겨찾기
              </div>
            </div>
            <br /><br /><br />
            <div class="playlist_txt">
              <p *ngIf="selectedVod.gangsa !== ''" class="name_txt">강사 {{ this.selectedVod.gangsa }}쌤</p>
              <p>{{ this.selectedVod.content }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div *ngIf="loading == true" style="width: 100%">
        <mat-spinner diameter="50" style="margin: 30% auto" class="custom-spinner"></mat-spinner>
      </div>
      <div *ngIf="loading == false" class="thumbnail_wrap">
        <div class="col-xs-4 col-sm-4 col-md-4 thumbnail_box" *ngFor="let vod of vodListCategory?.vod_list | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize; let i = index">
          <div (click)="changeSelectedContent(vod)"
               (mouseover)="playVideo(i)"
               (mouseout)="pauseVideo(i)">
            <div class="img_wrap video-container">
              <!-- thumbnail videos -->
              <div #themVod></div> 
            <img src="{{ vod.thumbnail }}" alt="thumbnail" title="{{ vod.title }}" /> 
          </div>
            <div class="thumbnail_txt">{{ vod.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <ngb-pagination
      [collectionSize]="vodListCategory?.vod_list.length"
      [(page)]="page"
      [pageSize]="pageSize"
      [maxSize]="5"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
      class="d-flex justify-content-center"
      style="font-size: 15px"
    >
    </ngb-pagination>
  </div>
</div>
<div class="body_bg"></div>
