<div class="wrap_find-juso">
  <h3>주소검색</h3>
  <hr />
  <h4>
    ※지번 및 도로명주소로 검색이 가능합니다. (예시: 연수구 송도동 하모니로
    35번길 2)
  </h4>
  <mat-dialog-content class="mat-typography">
    <mat-form-field style="width: 200px; margin-top: 30px">
      <input
        matInput
        [(ngModel)]="jusoKeyword"
        placeholder="지번 및 도로명주소 검색"
      />
    </mat-form-field>

    <button
      mat-button
      style="margin-left: 50px; border: 1px solid #c1c1c1;"
      (click)="searchJusos()"
    >
      <a>검색</a>
    </button>
    <br />
    <br />
    <ul>
      <li *ngFor="let juso of lessDetailJusos">
        <label
          style="font-size: 0.9em; cursor: pointer"
          (click)="jusoClicked(juso)"
          >{{ juso.jibunAddr }}</label
        >
      </li>
    </ul>
  </mat-dialog-content>
  <label style="color: gray" matLabel *ngIf="selectedJuso.jibunAddr != ''"
    ><hr />
    <h4>선택된 주소</h4></label
  ><br />
  <label style="font-size: 15px; color: #fec40d">{{
    selectedJuso.jibunAddr
  }}</label>
  <mat-dialog-actions align="end" style="padding-bottom: 22px">
    <button mat-raised-button mat-dialog-close>취소</button>
    <button
      mat-raised-button
      style="background-color: #fec40d"
      [mat-dialog-close]="selectedJusoStringed"
    >
      저장
    </button>
  </mat-dialog-actions>
</div>
