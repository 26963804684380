import { Component, OnInit, ViewChild, Inject, Input, QueryList, ViewChildren, Output, EventEmitter, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef, Injectable, Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import {Location} from '@angular/common'; 
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import * as TBM_ROLE from 'src/app/shared';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarDateFormatter, DateFormatterParams, CalendarEventTitleFormatter } from 'angular-calendar';
import { Subject, Subscription } from 'rxjs';
import { addHours, isSameMonth, isSameDay } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BoardService } from 'src/app/shared/services/board.service';

export interface NewsFeeds {
  id: number;
  title: string;
  body: string;
  cc: number;
  createDate: string;
  pin: boolean;
}
export interface FAQs {
  id: number;
  question: string;
  answer: string;
  cc: number;
  createDate: string;
}
export interface Resources {
  id: number;
  title: string;
  body: string;
  cc: number;
  createDate: string;
  type: number;
}

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {

  config: any = {
    height: '200px',
    fontsize: '20',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      // ['customButtons', ['testBtn']]
    ],
    // buttons: {
    //   'testBtn': this.customButton()
    // }
  };

  addNewsFeedInfo;
  newsfeeds : NewsFeeds[];
  newsfeedDisplayedColumns: string[] = ['title', 'cc', 'createDate' ]; // No body
  newsfeedDataSource;
  newsfeedForm: FormGroup;

  addFAQInfo;
  FAQs : FAQs[];
  faqDisplayedColumns: string[] = ['question', 'cc', 'createDate' ]; // no 'answer',
  faqDataSource;
  faqForm: FormGroup;

  addResourceInfo;
  memResources : Resources[];
  bizResources : Resources[];
  insResources : Resources[];
  resourceDisplayedColumns: string[] = ['title', 'cc', 'createDate' ]; // No body
  memResourceDataSource;
  bizResourceDataSource;
  insResourceDataSource;
  selectedReDataSource;
  resourceForm;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChild(MatPaginator, {static: true}) nfpaginator: MatPaginator;
  // @ViewChild(MatPaginator, {static: true}) faqpaginator: MatPaginator;
  // @ViewChild(MatPaginator, {static: true}) repaginator: MatPaginator;
  // @ViewChild('nfPaginator') nfPaginator: MatPaginator;
  // @ViewChild(MatSort, {static: true}) sort: MatSort;

  routeType = null;
  routeId = null;
  userrole;

  bodyLoading = false;
  subscription: Subscription; 

  constructor(
    private boardService: BoardService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public tr: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    private location: Location
  ) {
  }

  ngOnInit() {
    // this.routeType = this.route.snapshot.paramMap.get('type');
    this.route.params.subscribe(
      params => {
        this.routeType = params['type'];
        // const id = +params['id'];
        this.loadData();
      }
    );
  }

  loadData(){
    // let routeId = this.route.snapshot.paramMap.get('id');
    // console.log('routeType', this.routeType);
    if( this.routeType === null || this.routeType === undefined ){
      this.location.go('kr/community;type=nf');
      this.routeType = "nf";
    }
    if( this.routeType === "nf" ){
      this.loadNf();
    } else if ( this.routeType === "faq" ) {
      this.loadFaq();
    } else if ( this.routeType === "re" ) {
      this.loadRe();
    } else if ( this.routeType === "vc" ) {
      this.loadVc();
    } else {
      alert("Invalid community page");
      this.router.navigate(['kr/home']);
    }

  }

  loadNf(){
    this.addNewsFeedInfo = {
      title: '',
      body: ''
    }
    this.newsfeeds = [];
    this.subscription = this.boardService.getNoticeList().subscribe(
      data => {
        // console.log(data);
        data.forEach(element => {
          this.newsfeeds.push({
            id: element.id,
            title: element.title,
            // body: element.body,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            pin: element.pin
          });
        });
        this.newsfeedDataSource = new MatTableDataSource<NewsFeeds>(this.newsfeeds);
        this.newsfeedDataSource.paginator = this.paginator.toArray()[0];
      }, error => { }, () => {
        // this.selected.content_id = +this.route.snapshot.paramMap.get('id');
        // console.log(this.route.snapshot.paramMap.get('type'));
        if( this.route.snapshot.paramMap.get('type') === "nf" ){
          let checkId = +this.route.snapshot.paramMap.get('id')
          for( let i = 0; i < this.newsfeeds.length; i ++ ){
            if ( this.newsfeeds[i].id === checkId ){
              this.openViewer('nf', this.newsfeeds[i]);
              break;
            }
          }
        }
      }
    );
    this.newsfeedForm = new FormGroup({
      nfFormData: new FormControl()
    });
  }

  loadFaq(){

    this.addFAQInfo = {
      question: '',
      answer: ''
    }
    this.FAQs = [];
    // this.userService.getAllFAQWoAnswer().subscribe(
    this.subscription = this.boardService.getFaqList().subscribe(
      data => {
        data.forEach(element => {
          this.FAQs.push({
            id: element.id,
            question: element.question,
            // answer: element.answer,
            answer: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
          });
        });
        this.faqDataSource = new MatTableDataSource<FAQs>(this.FAQs);
        // this.faqDataSource.paginator = this.paginator.toArray()[1];
        this.faqDataSource.paginator = this.paginator.toArray()[0];
      }, error => { }, () => {
        // this.selected.content_id = +this.route.snapshot.paramMap.get('id');
        // console.log(this.route.snapshot.paramMap.get('type'));
        if( this.route.snapshot.paramMap.get('type') === "faq" ){
          let checkId = +this.route.snapshot.paramMap.get('id')
          for( let i = 0; i < this.FAQs.length; i ++ ){
            if ( this.FAQs[i].id === checkId ){
              this.openViewer('faq', this.FAQs[i]);
              break;
            }
          }
        }
      }
    );
    this.faqForm = new FormGroup({
      faqFormData: new FormControl()
    });
  }

  loadRe(){
    this.addResourceInfo = {
      title: '',
      body: '',
      type: 0,
    }
    this.memResources = [];
    this.bizResources = [];
    this.insResources  = [];
    this.subscription = this.boardService.getNewsList().subscribe(
      data => {
        data.forEach(element => {
          this.memResources.push({
            id: element.id,
            title: element.title,
            // body: element.body,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            type: 3
          });
        });
        this.memResourceDataSource = new MatTableDataSource<Resources>(this.memResources);
        // data.business_results.forEach(element => {
        //   this.bizResources.push({
        //     id: element.id,
        //     title: element.title,
        //     body: element.body,
        //     cc: element.click_count,
        //     createDate: this.datePipe.transform( element.create_date, 'short'),
        //     type: 4
        //   });
        // });
        // this.bizResourceDataSource = new MatTableDataSource<Resources>(this.bizResources);
        // data.instructor_results.forEach(element => {
        //   this.insResources.push({
        //     id: element.id,
        //     title: element.title,
        //     body: element.body,
        //     cc: element.click_count,
        //     createDate: this.datePipe.transform( element.create_date, 'short'),
        //     type: 5
        //   });
        // });
        // this.insResourceDataSource = new MatTableDataSource<Resources>(this.insResources);
        this.selectedReDataSource.data = this.memResourceDataSource.data;
      }, error => { }, () => {
        // this.selected.content_id = +this.route.snapshot.paramMap.get('id');
        // console.log(this.route.snapshot.paramMap.get('type'));
        if( this.route.snapshot.paramMap.get('type') === "re" ){
          let checkId = +this.route.snapshot.paramMap.get('id')
          for( let i = 0; i < this.memResources.length; i ++ ){
            if ( this.memResources[i].id === checkId ){
              this.openViewer('re', this.memResources[i]);
              break;
            }
          }
        }
        this.selectedReDataSource.paginator = this.paginator.toArray()[0];
      }
    );
    this.resourceForm = new FormGroup({
      resFormData: new FormControl()
    });

    this.selectedReDataSource = new MatTableDataSource<Resources>(this.memResources);
    // this.selectedReDataSource.paginator = this.paginator.toArray()[2];
    this.selectedReDataSource.paginator = this.paginator.toArray()[0];
  }

  loadVc(){

  }

  nav(to){
    this.close();
    this.location.go('kr/community;type='+to);
    this.router.navigate(['kr/community/',{type: to}])
  }

  applyNewsfeedFilter(filterValue: string) {
    this.newsfeedDataSource.filter = filterValue.trim().toLowerCase();
    if (this.newsfeedDataSource.paginator) {
      this.newsfeedDataSource.paginator.firstPage();
    }
  }

  applyFAQFilter(filterValue: string) {
    this.faqDataSource.filter = filterValue.trim().toLowerCase();
    if (this.faqDataSource.paginator) {
      this.faqDataSource.paginator.firstPage();
    }
  }

  changeReType(type){
    this.addResourceInfo.type = type;
  }

  changeResourceDataSource(t){
    switch(t){
      case 'mem': {
        this.selectedReDataSource.data = this.memResourceDataSource.data;
        // this.selectedReDataSource.paginator = this.paginator.toArray()[2];
        this.selectedReDataSource.paginator = this.paginator.toArray()[0];
        break;
      }
      case 'biz': {
        this.selectedReDataSource.data = this.bizResourceDataSource.data;
        // this.selectedReDataSource.paginator = this.paginator.toArray()[2];
        this.selectedReDataSource.paginator = this.paginator.toArray()[0];
        break;
      }
      case 'ins': {
        this.selectedReDataSource.data = this.insResourceDataSource.data;
        // this.selectedReDataSource.paginator = this.paginator.toArray()[2];
        this.selectedReDataSource.paginator = this.paginator.toArray()[0];
        break;
      }
      default: {
        this.selectedReDataSource.data = this.memResourceDataSource.data;
        // this.selectedReDataSource.paginator = this.paginator.toArray()[2];
        this.selectedReDataSource.paginator = this.paginator.toArray()[0];
        break;
      }
    }
  }

  sendinfo = {
    send: '',
    title: '',
    body: '',
  }
  openViewer( type, info){
    // type is nf or faq
    this.location.go('kr/community;type='+type+';id='+info.id);
    if ( type === 'nf' ){
      this.sendinfo.send = type;
      this.sendinfo.title = info.title;
      this.sendinfo.body = info.body;
      if ( info.body === "" ) {
        this.bodyLoading = true;
        this.subscription.unsubscribe();
        this.subscription = this.boardService.getNoticeRetrieve(info.id).subscribe(
          data => {
            this.sendinfo.body = data.content;
          }, error => { }
        );
      } else {
        this.bodyLoading = false;
      }
      this.boardService.addNoticeClick(info.id).subscribe(
        data => {}, error => {}
      );
    } else if ( type === 'faq' ){
      this.sendinfo.send = type;
      this.sendinfo.title = info.question;
      this.sendinfo.body = info.answer;
      if ( info.answer === "" ) {
        this.bodyLoading = true;
        this.subscription.unsubscribe();
        this.subscription = this.boardService.getFaqRetrieve(info.id).subscribe(
          data => {
            this.sendinfo.body = data.answer;
          }, error => { }
        );
      } else {
        this.bodyLoading = false;
      }
      this.boardService.addFAQClick(info.id).subscribe(
        data => {}, error => {}
      );
    } else if ( type ==='re' ){
      this.sendinfo.send = type;
      this.sendinfo.title = info.title;
      this.sendinfo.body = info.body;
      if ( info.body === "" ) {
        this.bodyLoading = true;
        this.subscription.unsubscribe();
        this.subscription = this.boardService.getNewsRetrieve(info.id).subscribe(
          data => {
            this.sendinfo.body = data.content;
          }, error => { }
        );
      } else { 
        this.bodyLoading = false;
      }
      this.boardService.addNewsClick(info.id).subscribe(
        data => {}, error => {}
      );
    } else {
      // error
    }
  }

  close(){
    this.sendinfo = {
      send: '',
      title: '',
      body: '',
    }
  }


}

@Component({
  selector: 'app-community-viewer',
  templateUrl: './community-viewer.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityViewerComponent {
  @Input() title: string;
  @Input() body: string;
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  constructor(){}

  closeViewer() {this.close.emit(true);window.scroll(0,0);}
}



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',

  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-community-notice',
  templateUrl: './community-notice.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityNoticeComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  newsfeedDisplayedColumns: string[] = ['title', 'cc', 'createDate' ]; // No body
  newsfeedDataSource;
  faqDataSource;
  memResourceDataSource;
  selectedReDataSource;

  seletedCate;
  routeType;

  newsfeeds : NewsFeeds[] = [];
  FAQs = [];
  memResources = [];
  constructor(
    private boardService: BoardService,
    private datePipe: DatePipe,
    public router: Router,
  ){
    this.boardService.getNoticeList().subscribe(
      data => {
        data.forEach(element => {
          this.newsfeeds.push({
            id: element.id,
            title: element.title,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            pin: element.pin
          });
        });
        this.newsfeedDataSource = new MatTableDataSource<NewsFeeds>(this.newsfeeds);
        this.newsfeedDataSource.paginator = this.paginator;

        this.seletedCate = this.newsfeedDataSource;
        this.routeType = 'nf';

      }, error => { }
    );
    this.boardService.getFaqList().subscribe(
      data => {
        data.forEach(element => {
          this.FAQs.push({
            id: element.id,
            title: element.question,
            answer: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
          });
        });
        this.faqDataSource = new MatTableDataSource<FAQs>(this.FAQs);
        this.faqDataSource.paginator = this.paginator;
      }, error => { }
    );

    this.boardService.getNewsList().subscribe(
      data => {
        data.forEach(element => {
          this.memResources.push({
            id: element.id,
            title: element.title,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            type: 3
          });
        });
        this.memResourceDataSource = new MatTableDataSource<Resources>(this.memResources);
        this.memResourceDataSource.paginator = this.paginator;
      }, error => { }
    );
  }

  ngOnInit() {
  }

  openNf(nfid){
    this.router.navigate(['kr/community/',{type: this.routeType , id: nfid }]);
  }
  changeCate(to) {
    switch(to){
      case 0 : this.seletedCate = this.newsfeedDataSource; this.routeType = 'nf'; break;
      case 1 : this.seletedCate = this.memResourceDataSource; this.routeType = 're'; break;
      case 2 : this.seletedCate = this.faqDataSource; this.routeType = 'faq'; break;
      default :this.seletedCate = this.newsfeedDataSource; this.routeType = 'nf'; break;
    }
  }
  nav(){
    this.router.navigate(['kr/community/',{type: this.routeType }]);
  }
}

// 스크롤 이벤트
$(document).ready(function() {
  $(document).on('click', '.scrTop', function() {
    $(window).scrollTop(0);
  });
});
