<div style="background-color: #fff; width: 100%; height: auto">
  <video width="100%" controls id="myvideo">
    <source src="../../../../assets/images/vod.mp4" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="width: 100%; height: 100%" type="video/mp4" />
    <source src="" type="video/ogg" />
    Your browser does not support the video tag.
  </video>
  <div style="color: #000; font-weight: 700; cursor: pointer; margin-top: 10px; float: right" (click)="onNoClick()">X Close</div>
</div>
<script>
  var elem = document.getElementById("myvideo");
  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
</script>
