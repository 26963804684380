<div class="wrap">
    <p class="title">프로필 수정</p>
    <div class="parents">
        <img class="profile-img" src="{{photoChanged}}"  alt="ProfilePhoto"/>
        <label for="upload_file">
            <div class="file-upload">
                <img class="fit-center" src="../../../assets/images/fileUploadIcon.png" alt=""/>
            </div>
        </label>
        <input type=file id="upload_file" accept="image/*" value="" (change)="fileUpload($event)" required=true >
    </div>
    <form>
        <input class="input-form" type="text" id="profileName" name="profileName" [(ngModel)]="tempName">
        <img class="pen" src="../../../assets/images/edit_pen.png"/>
    </form>
    <button class="btn-delete" *ngIf="!selectedProfile.is_default" (click)="changeState(4)">프로필 삭제</button>
    <br>
    <div>
        <button class="btn-save" (click)="saveUserProfile()">저장</button>
        <button (click)="changeState(1)">취소</button>
    </div>
</div>
<div class="body_bg"></div>
