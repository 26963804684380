<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 watched_table">

  <ng-container matColumnDef="vName">
    <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 70%;"> 제목 </th>
    <td mat-cell *matCellDef="let v" [ngClass]="'customWidthClass'" style="width: 70%;"> {{v.vName}} </td>
  </ng-container>

  <ng-container matColumnDef="datetime">
    <th mat-header-cell *matHeaderCellDef [ngClass]="'customWidthClass'" style="width: 30%;"> 날짜, 시간 </th>
    <td mat-cell *matCellDef="let v" [ngClass]="'customWidthClass'" style="width: 30%;"> {{v.datetime}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
