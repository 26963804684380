<div class="wrap">
  <mat-tab-group mat-align-tabs="center" dynamicHeight style="margin-top: 60px">
    <mat-tab label="계정정보">
      <div class="tab_tit"><h3>계정정보</h3></div>
      <div class="container">
        <div class="row">
          <!-- <div class="col-md-4">
            <div class="card_box img_card">
              <div class="card_tit"><h3 style="margin: 0">프로필 사진</h3></div>

              <div *ngIf="photoChanged === ''" class="profile_photo">
                <img src="../../../../../assets/images/tbox-store/profile.png" alt="ProfilePhoto" style="width: 100%" />
              </div>

              <div *ngIf="photoChanged !== ''" class="profile_photo img-preview">
                <img src="{{ photoChanged }}" alt="photoChanged" style="width: 100%; opacity: 0.5; z-index: -1" />
                <p>원 안이 보이는 부분입니다.</p>
              </div>
              <div style="display: flex; align-items: center; background-color: #f2f2f2">
                <i class="material-icons" style="cursor: help" title="사이즈 : 90 X 120 이상 150 X 200 이하(픽셀 기준), dpi : 300 권장, 파일형식 : JPG, 용량 : 200KB 이하">help</i>
                <div *ngIf="userData.type === 't'">
                  <input type="file" id="photo" name="photo" accept="image/*" (change)="fileUpload($event)" placeholder="프로필 사진" style="position: relative; bottom: 0" />
                </div>
              </div>
              <button mat-raised-button color="warn" *ngIf="photoChanged !== ''" (click)="updatePhoto()" style="border-radius: 0">프로필 사진 수정</button>
            </div>
          </div> -->

          <div class="col-md-12">
            <div class="card_box info_card">
              <div class="card_tit"><h3 style="margin: 0">회원정보</h3></div>
              <form>
                <table>
                  <tr>
                    <td>이름</td>
                    <td>{{ userData.fullname }}</td>
                  </tr>
                  <tr>
                    <td>이메일</td>
                    <td>{{ userData.email }}</td>
                  </tr>
                  <tr>
                    <td>아이디</td>
                    <td>{{ userData.username }}</td>
                  </tr>
                  <tr *ngIf="editInfoMode === false">
                    <td>휴대폰</td>
                    <td>{{ userData.cellphone }}</td>
                  </tr>
                  <tr *ngIf="editInfoMode === false">
                    <td>주소</td>
                    <td>{{ userData.address.jibunAddr }} {{ userData.address.detail_juso }}</td>
                  </tr>
                </table>
              </form>
              <div *ngIf="editInfoMode === true">
                <form class="cellphone_input" [formGroup]="editUserInfoForm" (ngSubmit)="updateCellphone()">
                  <mat-form-field class="example-width"> <input matInput type="text" formControlName="cnum1" placeholder="휴대폰" /> </mat-form-field>-
                  <mat-form-field class="example-width"> <input matInput type="text" formControlName="cnum2" placeholder="****" /> </mat-form-field>-
                  <mat-form-field class="example-width">
                    <input matInput type="text" formControlName="cnum3" placeholder="****" />
                  </mat-form-field>

                  <mat-error
                    class="error"
                    *ngIf="
                      submitted &&
                      (editUserInfoForm.get('cnum1').hasError('required') ||
                        editUserInfoForm.get('cnum1').hasError('minlength') ||
                        editUserInfoForm.get('cnum1').hasError('maxlength') ||
                        editUserInfoForm.get('cnum2').hasError('required') ||
                        editUserInfoForm.get('cnum2').hasError('minlength') ||
                        editUserInfoForm.get('cnum2').hasError('maxlength') ||
                        editUserInfoForm.get('cnum3').hasError('required') ||
                        editUserInfoForm.get('cnum3').hasError('minlength') ||
                        editUserInfoForm.get('cnum3').hasError('maxlength'))
                    "
                  >
                    휴대폰 번호의 형식이 잘못되었습니다
                  </mat-error>
                </form>
              </div>

              <div *ngIf="editAddrMode === true" class="address_search">
                <input type="text" placeholder="우편번호" [(ngModel)]="editAddr.zipNo" disabled /><br />
                <input type="text" placeholder="주소" [(ngModel)]="editAddr.jibunAddr" disabled /><br />
                <button mat-raised-button color="primary" (click)="openJusoDialog()" style="min-width: 140px"><!--{{'Search' | translate }}-->주소검색</button><br />
                <input type="text" placeholder="상세주소" [(ngModel)]="editAddr.detail_juso" />
              </div>
              <br />

              <form class="editPw" [formGroup]="editPasswordForm" (ngSubmit)="updatePassword()">
                <div *ngIf="updatePWMode === true">
                  <mat-form-field class="example-full-width">
                    <input matInput type="password" formControlName="currentPassword" placeholder="기존 비밀번호" />
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <input matInput type="password" formControlName="newPassword" placeholder="새 비밀번호" />
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <input matInput type="password" formControlName="checkNewPassword" placeholder="새 비밀번호 확인" value="" />
                  </mat-form-field>
                </div>
              </form>

              <div class="edit_btn">
                <button mat-raised-button color="primary" *ngIf="updatePWMode === false && editInfoMode === false && editAddrMode === false" (click)="setMode('pw')">비밀번호 변경</button>
                <button mat-raised-button color="primary" *ngIf="updatePWMode === false && editInfoMode === false && editAddrMode === false" (click)="setMode('cell')">휴대폰 수정</button>
                <button mat-raised-button color="primary" *ngIf="updatePWMode === false && editInfoMode === false && editAddrMode === false" (click)="setMode('addr')">주소 수정</button>
                <button mat-raised-button color="primary" *ngIf="editInfoMode === true" (click)="updateCellphone()">업데이트</button>
                <button mat-raised-button color="primary" *ngIf="editAddrMode === true" (click)="updateAddress()">업데이트</button>
                <button mat-raised-button color="primary" *ngIf="updatePWMode === true" (click)="updatePassword()">업데이트</button>
                <button mat-raised-button color="warn" *ngIf="editInfoMode === true || updatePWMode === true || editAddrMode === true" (click)="setMode('cancel')">취소</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div *ngIf="subscriptionData === true" class="col-md-12">
            <app-profile-subscription-user></app-profile-subscription-user>
          </div>

          <div *ngIf="subscriptionData === true" class="col-md-12">
            <div class="card_box">
              <div class="card_tit"><h3 style="margin: 0">정기 이용권 결제 내역</h3></div>
              <app-profile-subscription-receipt></app-profile-subscription-receipt>
            </div>
          </div>

          <!-- <div class="col-md-12">
            <div class="card_box">
              <div class="card_tit"><h3 style="margin: 0">일시불 이용권 결제내역</h3></div>

              <div class="youtube_event" style="display: none">
                유튜브 구독 이벤트 몇가지 유형 설명 추가하기 (유튜브 채널 구독과 알람을 인증하시면 개인이용권 ?개월 을 무료로 제공해 드립니다.)
                <div *ngIf="youtubeStatus === 0">YouTube 구독 이미지 애플리케이션 상태를 확인할 수 없습니다.</div>
                <div *ngIf="youtubeStatus === 1">
                  신청할 수 있습니다.
                  <input type="file" (change)="youtubeImageChange($event)" /><br />
                  <button mat-button (click)="applyForYoutubeImage()">신청</button>
                </div>
                <div *ngIf="youtubeStatus === 2">이미 신청했으며, 검토 중입니다.</div>
                <div *ngIf="youtubeStatus === 3">신청이 승인되었습니다</div>
              </div>

              <div>
                <app-profile-my-vouchers></app-profile-my-vouchers>
              </div>
              <div>
                ※ 등록된 이용권이 없습니다.
                <button mat-raised-button routerLink="/kr/membership">이용권 구매하기</button>
              </div>
            </div>
          </div> -->

          <!--회원탈퇴 버튼-->
          <div style="text-align: right; width: 100%; min-height: 0">
            <button class="profile_button out_member" (click)="deleteAccountOpen()">회원탈퇴</button>
          </div>

          <!--회원탈퇴 버튼을 누르면 나타나는 박스-->
          <div class="col-md-12" id="Delete_account" style="display: none">
            <div class="card_tit"><h4 style="text-align: left; font-size: 25px; padding: 5px">회원탈퇴</h4></div>
            <div class="card_box" style="border-color: #f44336; height: auto">
              <div style="padding: 30px 15px 0px; line-height: 25px" class="cancel_info">
                <p style="color: #d2d2d2">회원탈퇴를 신청하시면 해당 아이디는 <span style="color: #f44336">즉시 탈퇴처리</span>됩니다.</p>
                <p style="color: #d2d2d2">
                  회원탈퇴후, <span style="color: #f44336">14일동안은 회원가입이 불가능</span>합니다. 탈퇴와 재가입을 통해 아이디를 교체하면서 일반 이용자들께 피해를 끼치는 행위를 방지하기 위한 조치이오니 넓은
                  양해 바랍니다.
                </p>
                <p style="color: #d2d2d2">
                  회원탈퇴 즉시, 회원정보는 즉시 삭제되며 단, 아래의 <span style="color: #f44336">공공적 성격의 게시물</span>은 탈퇴후에도 삭제되지 않습니다. 탈퇴후에도 회원정보 삭제로 인해 작성자 본인을 확인할
                  수 없기 때문에 게시물 등의 삭제를 원하시는 경우에는 반드시 먼저 게시물을 삭제하신 후, 탈퇴를 신청하시기 바랍니다.
                </p>
                <p style="color: #d2d2d2">- 게시판 : 타인과 함께 사용하는 공적인 영역의 게시물과 댓글, 의견 등</p>
                <p style="color: #d2d2d2">- 구매내역 : 결제내역및 사용정보</p>
                <p style="color: #d2d2d2">- 진도현황 : 강의진도및 접속내역</p>
                <p style="color: #d2d2d2">개인정보 취급방침에 따라 불량이용 및 이용제한에 관한 기록은 3개월동안 삭제되지 않고 보관됩니다.</p>
              </div>
              <div style="text-align: center; margin-bottom: 15px">
                <button mat-stroked-button (click)="deleteAccountClose()" color="warn" style="border-color: #f44336; margin-right: 10px">취소하기</button
                ><!--회원탈퇴 내용 닫기-->
                <button mat-stroked-button (click)="deactivate()" style="border-color: #747474; color: #747474">탈퇴하기</button>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12">
            <div class="card_box">
              <label>{{ 'Barcode' | translate }}</label>
              <img src="../../../../assets/images/preparing_service.jpg" alt="">
            </div>
          </div> -->
        </div>
      </div>
    </mat-tab>

    <mat-tab label="시청 기록">
      <div class="tab_tit"><h3>시청 기록</h3></div>
      <div class="col-md-12">
        <div class="card_box">
          <div class="card_tit"><h3 style="margin: 0">시청한 Vod 리스트</h3></div>
          <app-vod-view-log></app-vod-view-log>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="즐겨 찾기">
      <div class="tab_tit"><h3>즐겨 찾기</h3></div>
      <app-my-favorite></app-my-favorite>
    </mat-tab>
    <!-- <mat-tab label="결제내역">
      <div class="tab_tit">
        <h3>결제내역</h3>
      </div>
      <img src="../../../../../assets/images/coming-soon.jpg" alt="">
    </mat-tab> -->

    <!--<mat-tab label="바코드" > 바코드 다시 개발 진행시 주석해제
      <div class="tab_tit">
        <h3>바코드</h3>
      </div>
      <div *ngIf="userData.role !== '6'" > The user is not an O@O user
    You must be a O2O user to view this page image
        <img src="../../../../../assets/images/coming-soon.jpg" alt="" width="100%">
      </div>
      <div *ngIf="userData.role === '6'" > The user is an O2O user
        <img src="../../../../../assets/images/coming-soon.jpg" alt="" width="100%">
      </div>
    </mat-tab> -->

    <!-- <mat-tab label="수강정보" >  수강정보 다시 개발 진행시 주석해제
      <div class="tab_tit">
        <h3>수강정보</h3>
      </div>
      <div *ngIf="userData.role !== '5'" > The user is not a gansa
    You must be a Ganga to view this page image
        <img src="../../../../../assets/images/coming-soon.jpg" alt="" width="100%">
      </div>
      <div *ngIf="userData.role === '5'" > The user is a gansa
        <img src="../../../../assets/images/preparing_service.jpg" alt="" width="100%">
      </div>
    </mat-tab> -->
  </mat-tab-group>
</div>
<div class="body_bg"></div>
