import { environment } from 'src/environments/environment';

export const s3Url: string = environment.cdnUrl + '/';
export const textData = [
  {
    "index":1,
    "text": "시간을 낭비하지 마세요.\n\
    혁신적인 5분 IoT 운동으로 건강을 챙기세요"
  },
  {
    "index":1,
    "text": "스마트하고 빠르며 효율적인 5분 IoT 운동으로 운동 루틴을 바꾸십시오!"
  },
  {
    "index":2,
    "text": "시간이 없나요? 문제 없습니다!\n\
    5분 IoT 운동으로 해결하세요!"
  },
  {
    "index":3,
    "text": "최고의 운동 경험!\n\
     스마트 IoT 장비, 실시간 분석, 그리고 하루 단 5분!"
  },
  {
    "index":4,
    "text": "당신의 피트니스 여정이 혁신되었습니다.\n\
    지금 판도를 바꾸는 5분 IoT 운동을 시도해보세요!"
  },
  {
    "index":5,
    "text": "운동을 좋아하지만 시간이 부족하신가요?\n\
    5분 IoT 운동으로 해결했습니다!"
  },
  {
    "index":6,
    "text": "피트니스의 미래\n\
    실시간 분석이 가능한 5분 IoT 운동을 만나보세요!"
  },
  {
    "index":7,
    "text": "강력한 5분 IoT 운동\n\
    매 순간이 중요하기 때문입니다!"
  },
  {
    "index":8,
    "text": "긴 운동은 잊으세요!\n\
    5분 IoT 운동과 함께 피트니스의 미래가 도래했습니다!"
  },
  {
    "index":9,
    "text": "IoT 장비로 운동을 더 스마트하게 만드세요!\n\
    – 모든 것을 바꾸는 5분 운동!"
  },
  {
    "index":10,
    "text": "피트니스의 새로운 시대가 열리다\n\
    – 실시간 분석이 가능한 건강체력교실"
  },
  {
    "index":11,
    "text": "운동 효과 극대화\n\
    –PAPS 새로운 운동을 시작해 보세요!"
  },
  {
    "index":12,
    "text": "미래는 지금입니다\n\
    - 혁신적인 스마트 운동을 경험하세요!"
  },
  {
    "index":13,
    "text": "IoT 운동 장비의 힘을 발견하십시오\n\
    – 이전과는 다른 방식으로 5분 운동을 극대화하십시오!"
  },
  {
    "index":14,
    "text": "더 이상 변명하지 마세요.\n\
    스마트하고 효율적인 IoT 운동으로 건강을 챙기세요!"
  },
  {
    "index":15,
    "text": "우리의 IoT 운동 장비로 운동을 강화하십시오\n\
    – 저항할 수 없는 스마트 운동!"
  },
  {
    "index":16,
    "text": "시간이 가장 중요합니다.\n\
    5분 IoT 운동으로 피트니스를 극대화하세요!"
  },
  {
    "index":17,
    "text": "실시간 분석 및 스마트 기능인 5분!\n\
    IoT 운동으로 피트니스 목표를 달성하세요!"
  },
  {
    "index":18,
    "text": "모든 것을 바꾸는 운동\n\
    - 티박스 운동으로 혁명을 경험하세요!"
  },
  {
    "index":19,
    "text": "실시간 분석 티박스 운동으로 피트니스의 미래를 경험하세요!"
  },
  {
    "index":20,
    "text": "스마트하고 빠르며 효율적인 5분 IoT 운동으로 운동 루틴을 바꾸십시오!"
  },
  {
    "index":21,
    "text": "혁신적인 IoT 운동으로 피트니스 루틴을 강화하세요.\n\
    피트니스의 미래가 여기에 있습니다!"
  },
  {
    "index":22,
    "text": "스마트하고 빠르며 효과적인 획기적인 IoT 운동으로 순식간에 건강해지세요!"
  },
  {
    "index":23,
    "text": "​​피트니스 혁명에 동참하세요\n\
    - 실시간 분석과 스마트 기능으로 스마트 운동을 해보세요!"
  },
  {
    "index":24,
    "text": "더 똑똑하게 운동하는 방법\n\
    – 스마트 사운드, 조명, 실시간 분석을 통한 IoT 운동!"
  },
  {
    "index":25,
    "text": "최첨단 5분 IoT 운동으로 즉시 건강을 유지하세요.\n\
    피트니스의 미래를 받아들이세요!"
  }
]