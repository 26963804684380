<div class="wrap">
  <p class="border_bar"></p>
  <p class="title-delete">프로필을 삭제하시겠습니까?</p>
  <img class="profile-img" src="{{ selectedProfile.photo }}" alt="ProfilePhoto" />
  <p>
    <img class="none-icon" src="../../../assets/images/defpf.svg" />
    <span>일반 프로필</span>
  </p>
  <p class="detail-delete">
    시청기록,운동 데이터,내가 찜한 콘텐츠 등
    <br />프로필의 모든 정보가 완전히 삭제됩니다.
  </p>
  <div>
    <button class="btn-delete-delete" (click)="deleteThisProfile()">삭제하기</button>
    <button (click)="changeState(3)">취소</button>
  </div>
  <p class="border_bar"></p>
</div>
<div class="body_bg"></div>
