import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  apiBoardUrl = environment.apiUrl + '/board';

  constructor(
    private http: HttpClient,
  ) { }
  returnUrl() {
    return this.apiBoardUrl;
  }
  
  getMainBanner(): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/mainbanner', httpOptionsNoToken);
  }

  getNoticeList(): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/notice', httpOptionsNoToken);
  }

  getNoticeRetrieve(id): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/notice/' + id, httpOptionsNoToken);
  }

  addNoticeClick(id): Observable<any> {
    const body = {}
    return this.http.post(this.apiBoardUrl + '/notice/' + id + '/add', body, httpOptionsNoToken);
  }

  getFaqList(): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/faq', httpOptionsNoToken);
  }

  getFaqRetrieve(id): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/faq/' + id, httpOptionsNoToken);
  }

  addFAQClick(id): Observable<any> {
    const body = {}
    return this.http.post(this.apiBoardUrl + '/notice/' + id + '/add', body, httpOptionsNoToken);
  }

  getNewsList(): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/news', httpOptionsNoToken);
  }

  getNewsRetrieve(id): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/news/' + id, httpOptionsNoToken);
  }

  addNewsClick(id): Observable<any> {
    const body = {}
    return this.http.post(this.apiBoardUrl + '/notice/' + id + '/add', body, httpOptionsNoToken);
  }

}