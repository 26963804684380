import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-reactivate-account',
  templateUrl: './reactivate-account.component.html',
  styleUrls: ['./reactivate-account.component.css']
})
export class ReactivateAccountComponent implements OnInit {
  @Output() reactivate: EventEmitter<boolean> =   new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  reactivateAccount(){
    this.reactivate.emit(true);
  }

}
