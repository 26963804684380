<div class="wrap">
  <!-- TODO remove when testing is done -->
  <div class="tab_tit">
    <h3>STORE</h3>
    <i class="material-icons">&#xe5cc;</i>
    <h3>이용권</h3>
  </div>

  <!-- 일반 이용권  -->
  <div class="container_wrap">
    <div class="container_left">
      <!-- TODO remove test comment after testing is done -->
      <div class="left_imgwrap">
        <img src="../../../../assets/images/ticket_black.png" alt="image" width="40%" />
      </div>
      <div class="left_textwrap">
        <p class="left_text1">콘텐츠 구독권</p>
        <p class="left_text2 first_text">
          <span>T-CYCLING 영상콘텐츠로 유산소 <br />운동을 더욱 즐겁게 하세요</span>
        </p>
        <P class="left_text3">
          개인회원 / 사업시설 회원은 별도로 <br />
          등록하여야 합니다 (저작권 법)
        </P>
      </div>
    </div>
    <div class="container_right">
      <div class="box_wrap">
        <div class="personal">
          <p class="right_title">개인이용권 구성</p>
          <div *ngFor="let sub of subscription">
            <div *ngIf="sub.type == 3" (click)="requestBillingKey(sub)" class="box">
              <div class="box_top">
                <p>{{ sub.name }}</p>
              </div>
              <div class="box_middle">
                <div class="img_wrap">
                  <img src="{{ sub.img }}" alt="이용권 소개 이미지" />
                </div>
                <div class="box_explain">
                  <div class="box_explain_position">
                    <!-- <p>
                      <span>
                        <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                      </span>
                      <span> 계정 공유 {{ subscription[0].mp_num }}명 </span>
                    </p> -->
                    <p>
                      <span>
                        <img src="../../../../assets/images/tbox-store/membership_device_img.svg" alt="" />
                      </span>
                      <span> 다양한 디바이스 시청 지원 </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="box_bottom">
                <p>
                  <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                  <span>월 {{ sub.price.toLocaleString() }}원</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="biz">
          <p class="right_title">비즈이용권 구성</p>
          <div *ngFor="let sub of subscription">
            <div *ngIf="sub.type == 4" (click)="requestBillingKey(sub)" class="box">
              <div class="box_top">
                <p>{{ sub.name }}</p>
              </div>
              <div class="box_middle">
                <div class="img_wrap">
                  <img src="{{ sub.img }}" alt="이용권 소개 이미지" />
                </div>
                <div class="box_explain">
                  <div class="box_explain_position">
                    <!-- <p>
                      <span>
                        <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                      </span>
                      <span> 계정 공유 {{ sub.mp_num }}명 </span>
                    </p> -->
                    <p>
                      <span>
                        <img src="../../../../assets/images/tbox-store/membership_device_img.svg" alt="" />
                      </span>
                      <span> 멀티 디바이스 시청 지원 </span>
                    </p>
                    <p>
                      <span>
                        <img src="../../../../assets/images/tbox-store/content.png" />
                      </span>
                      <span> 강사용 데이터 측정센서 제공 </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="box_bottom">
                <p>
                  <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                  <span>월 {{ sub.price.toLocaleString() }}원</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 렌탈 서비스  -->
  <div class="container_wrap">
    <div class="biz_container_left">
      <!-- TODO remove test comment after testing is done -->
      <div class="left_imgwrap">
        <img src="../../../../assets/images/ticket.png" alt="image" width="40%" />
      </div>
      <div class="left_textwrap">
        <p class="left_text1">렌탈서비스</p>
        <p class="left_text2 second_txt">
          <span>T-BOX & T-CYCLING 운동에 필요한 제품을 편리하게 정기렌탈 서비스 하세요</span>
        </p>
        <P class="left_text3"> 상업용시설 전용 정기결제 이용권입니다. </P>
      </div>
    </div>
    <div class="container_right">
      <p class="Tbox_img">
        <img src="../../../../assets/images/tbox-store/티박스 이미지.png" />
      </p>
      <div class="box_wrap">
        <div *ngFor="let sub of subscription">
          <div *ngIf="sub.type == 5" (click)="requestBillingKey(sub)" class="box">
            <div class="box_top">
              <p>{{ sub.name }}</p>
            </div>
            <div class="box_middle">
              <div class="img_wrap">
                <img src="{{ sub.img }}" alt="이용권 소개 이미지" />
              </div>
              <div class="box_explain">
                <div class="box_explain_position">
                  <!-- <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                    </span>
                    <span> 계정 공유 {{ sub.mp_num }}명 </span>
                  </p> -->
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_device_img.svg" alt="" />
                    </span>
                    <span> 입문용 베이직 모델 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/video.svg" alt="" />
                    </span>
                    <span> 영상콘텐츠 </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="box_bottom">
              <p>
                <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                <span>월 {{ sub.price.toLocaleString() }}원</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="box">
            <div class="box_top">
              <p>T-BOX SMART 모델</p>
            </div>
            <div class="box_middle">
              <div class="img_wrap">
                <img src="../../../../assets/images/tbox-store/티박스 스마트.png" alt="이용권 소개 이미지" />
              </div>
              <div class="box_explain">
                <div class="box_explain_position">
                  <!-- <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                    </span>
                    <span> 계정 공유 {{ sub.mp_num }}명 </span>
                  </p> -->
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_device_img.svg" alt="" />
                    </span>
                    <span> IOT 스마트 모델</span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/video.svg" alt="" />
                    </span>
                    <span> 영상콘텐츠 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/data.svg" alt="" />
                    </span>
                    <span> 운동데이터 </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="box_bottom">
              <p>
                <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                <span>출시예정</span>
              </p>
            </div>
          </div>
        </div>

        <div>
          <div class="box">
            <div class="box_top">
              <p>T-CYCLING 스핀바이크</p>
            </div>
            <div class="box_middle">
              <div class="img_wrap">
                <img src="../../../../assets/images/tbox-store/Group 2054.png" alt="이용권 소개 이미지" />
              </div>
              <div class="box_explain">
                <div class="box_explain_position">
                  <!-- <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                    </span>
                    <span> 계정 공유 {{ sub.mp_num }}명 </span>
                  </p> -->
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_device_img.svg" alt="" />
                    </span>
                    <span> 클럽용 모델 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/video.svg" alt="" />
                    </span>
                    <span> 영상콘텐츠 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/data.svg" alt="" />
                    </span>
                    <span> 운동데이터 </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="box_bottom">
              <p>
                <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                <span>출시예정</span>
              </p>
            </div>
          </div>
        </div>

        <!-- <div *ngFor="let sub of subscription">
          <div *ngIf="sub.type == 3" (click)="requestBillingKey(subscription[4])" class="box">
            <div class="box_top">
              <p>{{ subscription[4].name }}</p>
            </div>
            <div class="box_middle">
              <div class="img_wrap">
                <img src="{{ subscription[4].img }}" alt="이용권 소개 이미지" />
              </div>
              <div class="box_explain">
                <div class="box_explain_position">
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                    </span>
                    <span> 계정 공유 {{ subscription[4].mp_num }}명 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_device_img.svg" alt="" />
                    </span>
                    <span> 데이터 측정 스마트 모델 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_contents_img.svg" alt="" />
                    </span>
                    <span> 전문상담 코치배정 </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="box_bottom">
              <p>
                <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                <span>월 {{ subscription[4].price.toLocaleString() }}원</span>
              </p>
            </div>
          </div>
        </div>

        <div *ngFor="let sub of subscription">
          <div *ngIf="sub.type == 3" (click)="requestBillingKey(subscription[5])" class="box">
            <div class="box_top">
              <p>{{ subscription[5].name }}</p>
            </div>
            <div class="box_middle">
              <div class="img_wrap">
                <img src="{{ subscription[5].img }}" alt="이용권 소개 이미지" />
              </div>
              <div class="box_explain">
                <div class="box_explain_position">
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/membership_share_img.svg" alt="" />
                    </span>
                    <span> 계정 공유 {{ subscription[5].mp_num }}명 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/content.png" alt="" />
                    </span>
                    <span> 데이터측정 센서장착 </span>
                  </p>
                  <p>
                    <span>
                      <img src="../../../../assets/images/tbox-store/consulting.png" alt="" />
                    </span>
                    <span> 상담 후 결제 </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="box_bottom">
              <p>
                <img src="../../../../assets/images/ticket_black.png" alt="image" width="20%" />
                <span>월 {{ subscription[5].price.toLocaleString() }}원</span>
              </p>
            </div>
          </div>
        </div>--->
      </div>
    </div>
  </div>
  <div class="caution_wrap">
    <div class="catuion_textwrap">
      <h4>멤버십 약관(요약)</h4>
      <p>1. 콘텐츠는 제공사의 사정에 따라 수시 변경.정정.삭제.지원되지 않을 수 있습니다.</p>
      <p>2. 다운로드 이용권은 기간 및 회수를 이월하지 않습니다.</p>
      <p>3. 제휴 이용권은 할인 혜택이 적용되어 있으므로 추가 혜택(페이백, 기타 할인 등)이 적용되지 않습니다.</p>

      <h4>서비스 이용 주의사항(요약)</h4>
      <p>1. T-BOX MEDIA 서비스는 저작권 문제로 해외 서비스 이용 시 이용금액이 다를 수 있습니다.</p>
      <p>2. 동시접속 상품은 한 계정당 구매하신 회선만큼 동시에 여러 기기에서 시청하실 수 있는 상품입니다.</p>
      <p>3. 일부 콘텐츠는 저작권자의 요청에 따라 LIVE 및 Vod 서비스가 제공되지 않을 수 있습니다.</p>
      <p>4. 일부 콘텐츠는 콘텐츠 제공사의 사정에 따라 FHD화질이 제공되지 않을 수 있습니다.</p>
      <p>5. 콘텐츠는 회원권별로 제공사항이 제한될 수 있습니다.</p>

      <h4>이용권 구매 주의사항(요약)</h4>
      <p>1. 모든 이용권 금액에는 부가세(VAT)가 포함되어 있으며, 상품 가격은 내부 정책으로 인해 변경될 수 있습니다.</p>
      <p>2. 할인가격은 프로모션 종료 후 변경 될 수 있습니다.</p>
      <br />
      <p>※세부적인 이용약관 및 개인정보 보호관련 처리방침을 확인하여 주시기 바랍니다.</p>
    </div>
  </div>
</div>
